import axios from "@/plugins/axios";

const url = "/platform/api/v1/statistics/";

const getStatisticsCounters = () => {
  return axios.get(`${url}counters`);
};

const getStatisticsCustomers = (data) => {
  return axios.get(`${url}customers?period=${data}`);
};

const getStatisticsRequests = (data) => {
  return axios.get(`${url}requests?period=${data}`);
};

export default {
  getStatisticsCounters,
  getStatisticsCustomers,
  getStatisticsRequests,
};
