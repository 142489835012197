import agentApi from "@/api/agent";
import { itemsPerPage } from "@/helpers/paginations";
import { i18n } from "@/plugins/i18n";

const state = {
  data: {},
  errors: [],
  isLoading: false,
  searchValue: "",
  page: 1,
  itemsPerPage: itemsPerPage,
  agent: {},
  isSearching: false,
  agentsList: [],
  isAssigning: false,
  isUpdating: false,
};

export const mutationTypes = {
  getAgentsStart: "[agent] getAgentsStart",
  getAgentsFailed: "[agent] getAgentsFailed",
  getAgentsSuccess: "[agent] getAgentsSuccess",

  getAgentsListStart: "[agent] getAgentsListStart",
  getAgentsListFailed: "[agent] getAgentsListFailed",
  getAgentsListSuccess: "[agent] getAgentsListSuccess",

  getAgentStart: "[agent] getAgentStart",
  getAgentFailed: "[agent] getAgentFailed",
  getAgentSuccess: "[agent] getAgentSuccess",

  changeSearchValue: "[agent] changeSearchValue",
  changePagination: "[agent] changePagination",

  activateStart: "[agent] activateStart",
  activateFailed: "[agent] activateFailed",
  activateSuccess: "[agent] activateSuccess",
  deactivateStart: "[agent] deactivateStart",
  deactivateFailed: "[agent] deactivateFailed",
  deactivateSuccess: "[agent] deactivateSuccess",

  resetStart: "[agent] resetStart",
  resetFailed: "[agent] resetFailed",
  resetSuccess: "[agent] resetSuccess",

  assignPropertiesStart: "[agent] assignPropertiesStart",
  assignPropertiesFailed: "[agent] assignPropertiesFailed",
  assignPropertiesSuccess: "[agent] assignPropertiesSuccess",
};

const mutations = {
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },

  [mutationTypes.resetStart](state) {
    state.isUpdating = true;
    state.errors = [];
  },
  [mutationTypes.resetSuccess](state) {
    state.isUpdating = false;
    state.errors = [];
  },
  [mutationTypes.resetFailed](state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },

  [mutationTypes.getAgentsStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getAgentsSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getAgentsFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getAgentsListStart](state) {
    state.isSearching = true;
    state.errors = [];
  },
  [mutationTypes.getAgentsListSuccess](state, payload) {
    state.isSearching = false;
    state.errors = [];
    state.agentsList = payload;
  },
  [mutationTypes.getAgentsListFailed](state, payload) {
    state.isSearching = false;
    state.errors = payload;
  },

  [mutationTypes.assignPropertiesStart](state) {
    state.isAssigning = true;
    state.errors = [];
  },
  [mutationTypes.assignPropertiesSuccess](state) {
    state.isAssigning = false;
    state.errors = [];
  },
  [mutationTypes.assignPropertiesFailed](state, payload) {
    state.isAssigning = false;
    state.errors = payload;
  },

  [mutationTypes.getAgentStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getAgentSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.agent = payload;
  },
  [mutationTypes.getAgentFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  [mutationTypes.activateStart](state) {
    state.isActivating = true;
    state.errors = [];
  },
  [mutationTypes.activateSuccess](state, payload) {
    state.isActivating = false;
    state.agent = payload;
    state.errors = [];
  },
  [mutationTypes.activateFailed](state, payload) {
    state.isActivating = false;
    state.errors = payload;
  },
  [mutationTypes.deactivateStart](state) {
    state.isDeactivating = true;
    state.errors = [];
  },
  [mutationTypes.deactivateSuccess](state, payload) {
    state.isDeactivating = false;
    state.agent = payload;
    state.errors = [];
  },
  [mutationTypes.deactivateFailed](state, payload) {
    state.isDeactivating = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getAgents: "[agent] getAgents",
  getAgentsList: "[agent] getAgentsList",
  getAgent: "[agent] getAgent",
  activateAgent: "[agent] activateAgent",
  deactivateAgent: "[agent] deactivateAgent",
  resetAgent: "[agent] resetAgent",
  assignProperties: "[agent] assignProperties",
};

const actions = {
  async [actionTypes.resetAgent](context, payload) {
    try {
      context.commit(mutationTypes.resetStart);

      await agentApi.toggleAgencyAgent(payload);
      context.commit(mutationTypes.resetSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.resetFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.assignProperties](context, payload) {
    try {
      context.commit(mutationTypes.assignPropertiesStart);

      await agentApi.assignPropertiesAgent(payload);
      context.commit(mutationTypes.assignPropertiesSuccess);
      return true;
    } catch (e) {
      context.commit(
        mutationTypes.assignPropertiesFailed,
        e.response.data.error
      );
      return false;
    }
  },
  async [actionTypes.getAgents](context, payload) {
    try {
      context.commit(mutationTypes.getAgentsStart);

      const res = await agentApi.getAgents(payload);
      context.commit(mutationTypes.getAgentsSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAgentsFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getAgentsList](context, payload) {
    try {
      context.commit(mutationTypes.getAgentsListStart);

      const res = await agentApi.getAgentsList(payload);
      context.commit(mutationTypes.getAgentsListSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAgentsListFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getAgent](context, payload) {
    try {
      context.commit(mutationTypes.getAgentStart);

      const res = await agentApi.getAgent(payload);
      context.commit(mutationTypes.getAgentSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAgentFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.activateAgent](context, payload) {
    try {
      context.commit(mutationTypes.activateStart);

      const res = await agentApi.activateAgent(payload);
      context.commit(mutationTypes.activateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.activateFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.deactivateAgent](context, payload) {
    try {
      context.commit(mutationTypes.deactivateStart);

      const res = await agentApi.deactivateAgent(payload);
      context.commit(mutationTypes.deactivateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.deactivateFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  totalItems: "[agent] totalItems",
  totalPages: "[agent] totalPages",
  agentAgencyErrors: "[agent] agencyErrors",
};
const getters = {
  [getterTypes.agentAgencyErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "agency__invalid") &&
      result.push(i18n.t("errors.agencyInvalid"));
    return result;
  },
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
