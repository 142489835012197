import axios from "@/plugins/axios";

const apiUrl = "/platform/api/v1/customers/";

const getCustomers = (params) => {
  return axios.get(`${apiUrl}?${params}`);
};

const getCustomer = (id) => {
  return axios.get(`${apiUrl}${id}`);
};

export default {
  getCustomers,
  getCustomer,
};
