import ticketApi from "@/api/ticket";
import { itemsPerPage } from "@/helpers/paginations";

const state = {
  errors: [],
  isLoading: false,
  data: {},
  dataList: {},
  ticket: null,
  isTicketLoading: false,
  searchValue: "",
  page: 1,
  itemsPerPage: itemsPerPage,
  isMsgsLoading: false,
  messages: [],
  isHandling: false,
  isSending: false,
  isMediaSending: false,
  isAssigning: false,
  newMessage: null,
  updatedConversation: null,
};

export const mutationTypes = {
  addNewMessage: "[ticket] addNewMessage",
  addUpdatedConversation: "[ticket] addUpdatedConversation",
  getTicketsStart: "[ticket] getTicketsStart",
  getTicketsFailed: "[ticket] getTicketsFailed",
  getTicketsSuccess: "[ticket] getTicketsSuccess",

  getTicketsListStart: "[ticket] getTicketsListStart",
  getTicketsListFailed: "[ticket] getTicketsListFailed",
  getTicketsListSuccess: "[ticket] getTicketsListSuccess",

  getTicketStart: "[ticket] getTicketStart",
  getTicketFailed: "[ticket] getTicketFailed",
  getTicketSuccess: "[ticket] getTicketSuccess",

  changeSearchValue: "[ticket] changeSearchValue",
  changePagination: "[ticket] changePagination",
  resetErrors: "[ticket] resetErrors",

  getMessagesStart: "[ticket] getMessagesStart",
  getMessagesFailed: "[ticket] getMessagesFailed",
  getMessagesSuccess: "[ticket] getMessagesSuccess",

  handleTicketStart: "[ticket] handleTicketStart",
  handleTicketFailed: "[ticket] handleTicketFailed",
  handleTicketSuccess: "[ticket] handleTicketSuccess",

  sendMessageStart: "[ticket] sendMessageStart",
  sendMessageFailed: "[ticket] sendMessageFailed",
  sendMessageSuccess: "[ticket] sendMessageSuccess",

  sendMediaStart: "[ticket] sendMediaStart",
  sendMediaFailed: "[ticket] sendMediaFailed",
  sendMediaSuccess: "[ticket] sendMediaSuccess",

  assignSupportStart: "[ticket] assignSupportStart",
  assignSupportFailed: "[ticket] assignSupportFailed",
  assignSupportSuccess: "[ticket] assignSupportSuccess",

  setTicket: "[ticket] setTicket",
};

const mutations = {
  [mutationTypes.addNewMessage](state, payload) {
    state.newMessage = payload;
  },
  [mutationTypes.addUpdatedConversation](state, payload) {
    state.updatedConversation = payload;
  },
  [mutationTypes.setTicket](state, payload) {
    state.ticket = payload;
  },
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },

  [mutationTypes.getTicketsStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getTicketsSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getTicketsFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getTicketsListStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getTicketsListSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.dataList = payload;
  },
  [mutationTypes.getTicketsListFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getTicketStart](state) {
    state.isTicketLoading = true;
    state.errors = [];
  },
  [mutationTypes.getTicketSuccess](state, payload) {
    state.isTicketLoading = false;
    state.errors = [];
    state.ticket = payload;
  },
  [mutationTypes.getTicketFailed](state, payload) {
    state.isTicketLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getMessagesStart](state) {
    state.isMsgsLoading = true;
    state.errors = [];
  },
  [mutationTypes.getMessagesSuccess](state, payload) {
    state.isMsgsLoading = false;
    state.errors = [];
    state.messages = payload;
  },
  [mutationTypes.getMessagesFailed](state, payload) {
    state.isMsgsLoading = false;
    state.errors = payload;
  },

  [mutationTypes.handleTicketStart](state) {
    state.isHandling = true;
    state.errors = [];
  },
  [mutationTypes.handleTicketSuccess](state, payload) {
    state.isHandling = false;
    state.errors = [];
    state.ticket = payload;
  },
  [mutationTypes.handleTicketFailed](state, payload) {
    state.isHandling = false;
    state.errors = payload;
  },

  [mutationTypes.sendMessageStart](state) {
    state.isSending = true;
    state.errors = [];
  },
  [mutationTypes.sendMessageSuccess](state) {
    state.isSending = false;
    state.errors = [];
  },
  [mutationTypes.sendMessageFailed](state, payload) {
    state.isSending = false;
    state.errors = payload;
  },
  [mutationTypes.sendMediaStart](state) {
    state.isMediaSending = true;
    state.errors = [];
  },
  [mutationTypes.sendMediaSuccess](state) {
    state.isMediaSending = false;
    state.errors = [];
  },
  [mutationTypes.sendMediaFailed](state, payload) {
    state.isMediaSending = false;
    state.errors = payload;
  },

  [mutationTypes.assignSupportStart](state) {
    state.isAssigning = true;
    state.errors = [];
  },
  [mutationTypes.assignSupportSuccess](state) {
    state.isAssigning = false;
    state.errors = [];
  },
  [mutationTypes.assignSupportFailed](state, payload) {
    state.isAssigning = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getTickets: "[ticket] getTickets",
  getTicketsList: "[ticket] getTicketsList",
  getTicket: "[ticket] getTicket",
  getMessages: "[ticket] getMessages",
  handleTicket: "[ticket] handleTicket",
  sendMessage: "[ticket] sendMessage",
  sendMedia: "[ticket] sendMedia",
  assignSupport: "[ticket] assignSupport",
};

const actions = {
  async [actionTypes.getTickets](context, payload) {
    try {
      context.commit(mutationTypes.getTicketsStart);

      const res = await ticketApi.getTickets(payload);
      context.commit(mutationTypes.getTicketsSuccess, res.data);
      return res.data;
    } catch (e) {
      context.commit(mutationTypes.getTicketsFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getTicketsList](context, payload) {
    try {
      context.commit(mutationTypes.getTicketsListStart);

      const res = await ticketApi.getTickets(payload);
      context.commit(mutationTypes.getTicketsListSuccess, res.data);
      return res.data;
    } catch (e) {
      context.commit(mutationTypes.getTicketsListFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getTicket](context, payload) {
    try {
      context.commit(mutationTypes.getTicketStart);

      const res = await ticketApi.getTicket(payload);
      context.commit(mutationTypes.getTicketSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getTicketFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getMessages](context, payload) {
    try {
      context.commit(mutationTypes.getMessagesStart);

      const res = await ticketApi.getMessages(payload);
      context.commit(mutationTypes.getMessagesSuccess, res.data);
      return res.data;
    } catch (e) {
      context.commit(mutationTypes.getMessagesFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.handleTicket](context, payload) {
    try {
      context.commit(mutationTypes.handleTicketStart);

      const res = await ticketApi.handleTicket(payload);
      context.commit(mutationTypes.handleTicketSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.handleTicketFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.sendMessage](context, payload) {
    try {
      context.commit(mutationTypes.sendMessageStart);

      const res = await ticketApi.sendMessage(payload);
      context.commit(mutationTypes.sendMessageSuccess);
      return res.data;
    } catch (e) {
      context.commit(mutationTypes.sendMessageFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.sendMedia](context, payload) {
    try {
      context.commit(mutationTypes.sendMediaStart);

      const res = await ticketApi.sendMedia(payload);
      context.commit(mutationTypes.sendMediaSuccess);
      return res.data;
    } catch (e) {
      context.commit(mutationTypes.sendMediaFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.assignSupport](context, payload) {
    try {
      context.commit(mutationTypes.assignSupportStart);

      const res = await ticketApi.assignSupport(payload);
      context.commit(mutationTypes.assignSupportSuccess);
      return res.data;
    } catch (e) {
      context.commit(mutationTypes.assignSupportFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  totalItems: "[ticket] totalItems",
  totalPages: "[ticket] totalPages",
  ticketUnreadMessages: "[ticket] ticketUnreadMessages",
};

const getters = {
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },
  [getterTypes.ticketUnreadMessages](state) {
    return state.ticket?.unread_count || 0;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
