import staffApi from "@/api/staff";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isSubmitting: false,
  member: {},
  staff: {},
  errors: [],
  isResending: false,
  isResetting: false,
  isUpdatingAvatar: false,
  isActivating: false,
  searchValue: "",
  page: 1,
};

export const mutationTypes = {
  getStaffStart: "[staff] getStaffStart",
  getStaffFailed: "[staff] getStaffFailed",
  getStaffSuccess: "[staff] getStaffSuccess",
  createMemberStart: "[staff] createMemberStart",
  createMemberFailed: "[staff] createMemberFailed",
  createMemberSuccess: "[staff] createMemberSuccess",

  getMemberStart: "[staff] getMemberStart",
  getMemberFailed: "[staff] getMemberFailed",
  getMemberSuccess: "[staff] getMemberSuccess",

  updateMemberStart: "[staff] updateMemberStart",
  updateMemberFailed: "[staff] updateMemberFailed",
  updateMemberSuccess: "[staff] updateMemberSuccess",

  resendEmailStart: "[staff] resendEmailStart",
  resendEmailFailed: "[staff] resendEmailFailed",
  resendEmailSuccess: "[staff] resendEmailSuccess",

  updateAvatarStart: "[staff] updateAvatarStart",
  updateAvatarFailed: "[staff] updateAvatarFailed",
  updateAvatarSuccess: "[staff] updateAvatarSuccess",

  resetPasswordStart: "[staff] resetPasswordStart",
  resetPasswordFailed: "[staff] resetPasswordFailed",
  resetPasswordSuccess: "[staff] resetPasswordSuccess",

  changeStatusStart: "[staff] changeStatusStart",
  changeStatusFailed: "[staff] changeStatusFailed",
  changeStatusSuccess: "[staff] changeStatusSuccess",

  resetErrors: "[staff] resetErrors",
  changeSearchValue: "[staff] changeSearchValue",
  changePagination: "[staff] changePagination",
};

const mutations = {
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },
  [mutationTypes.changePagination](state, { page }) {
    state.page = page;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },

  [mutationTypes.getStaffStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getStaffSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.staff = payload;
  },
  [mutationTypes.getStaffFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.createMemberStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.createMemberSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createMemberFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.getMemberStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getMemberSuccess](state, payload) {
    state.isLoading = false;
    state.member = payload;
  },
  [mutationTypes.getMemberFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.updateMemberStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.updateMemberSuccess](state, payload) {
    state.isSubmitting = false;
    state.member = payload;
  },
  [mutationTypes.updateMemberFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.resendEmailStart](state) {
    state.isResending = true;
    state.errors = [];
  },
  [mutationTypes.resendEmailSuccess](state, payload) {
    state.isResending = false;
    state.errors = [];
    state.member = payload;
  },
  [mutationTypes.resendEmailFailed](state, payload) {
    state.isResending = false;
    state.errors = payload;
  },

  [mutationTypes.updateAvatarStart](state) {
    state.isUpdatingAvatar = true;
    state.errors = [];
  },
  [mutationTypes.updateAvatarSuccess](state, payload) {
    state.isUpdatingAvatar = false;
    state.member = payload;
  },
  [mutationTypes.updateAvatarFailed](state, payload) {
    state.isUpdatingAvatar = false;
    state.errors = payload;
  },

  [mutationTypes.resetPasswordStart](state) {
    state.isResetting = true;
    state.errors = [];
  },
  [mutationTypes.resetPasswordSuccess](state) {
    state.isResetting = false;
    state.errors = [];
  },
  [mutationTypes.resetPasswordFailed](state, payload) {
    state.isResetting = false;
    state.errors = payload;
  },

  [mutationTypes.changeStatusStart](state) {
    state.isActivating = true;
    state.errors = [];
  },
  [mutationTypes.changeStatusSuccess](state, payload) {
    state.member = payload;
    state.isActivating = false;
    state.errors = [];
  },
  [mutationTypes.changeStatusFailed](state, payload) {
    state.isActivating = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getStaff: "[staff] getStaff",
  createMember: "[staff] createMember",
  getMember: "[staff] getMember",
  updateMember: "[staff] updateMember",
  resendEmail: "[staff] resendEmail",
  updateAvatar: "[staff] updateAvatar",
  resetPassword: "[staff] resetPassword",
  suspendMember: "[staff] suspendMember",
  restoreMember: "[staff] restoreMember",
};

const actions = {
  async [actionTypes.getStaff](context, payload) {
    try {
      context.commit(mutationTypes.getStaffStart);

      const res = await staffApi.getStaff(payload);
      context.commit(mutationTypes.getStaffSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getStaffFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.createMember](context, payload) {
    try {
      context.commit(mutationTypes.createMemberStart);

      await staffApi.createMember(payload);
      context.commit(mutationTypes.createMemberSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.createMemberFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getMember](context, payload) {
    try {
      context.commit(mutationTypes.getMemberStart);

      const res = await staffApi.getMember(payload);
      context.commit(mutationTypes.getMemberSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getMemberFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateMember](context, { id, data }) {
    try {
      context.commit(mutationTypes.updateMemberStart);

      const res = await staffApi.updateMember(id, data);
      context.commit(mutationTypes.updateMemberSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateMemberFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.resendEmail](context, payload) {
    try {
      context.commit(mutationTypes.resendEmailStart);

      const res = await staffApi.resendEmail(payload);
      context.commit(mutationTypes.resendEmailSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.resendEmailFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateAvatar](context, { id, data }) {
    try {
      context.commit(mutationTypes.updateAvatarStart);
      const res = await staffApi.updateAvatar(id, data);
      context.commit(mutationTypes.updateAvatarSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateAvatarFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.resetPassword](context, payload) {
    try {
      context.commit(mutationTypes.resetPasswordStart);

      await staffApi.resetPassword(payload);
      context.commit(mutationTypes.resetPasswordSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.resetPasswordFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.suspendMember](context, payload) {
    try {
      context.commit(mutationTypes.changeStatusStart);

      const res = await staffApi.suspendMember(payload);
      context.commit(mutationTypes.changeStatusSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.changeStatusFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.restoreMember](context, payload) {
    try {
      context.commit(mutationTypes.changeStatusStart);

      const res = await staffApi.restoreMember(payload);
      context.commit(mutationTypes.changeStatusSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.changeStatusFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  emailErrors: "[staff] emailErrors",
  firstNameErrors: "[staff] firstNameErrors",
  lastNameErrors: "[staff] lastNameErrors",
  roleErrors: "[staff] roleErrors",
  isEmailConfirmed: "[staff] isEmailConfirmed",
  isSuspended: "[staff] isSuspended",
  memberFullName: "[staff] memberFullName",
  organizationErrors: "[staff] organizationErrors",
  staffList: "[staff] staffList",
  phoneErrors: "[staff] phoneErrors",

  totalItems: "[staff] totalItems",
  totalPages: "[staff] totalPages",
};

const getters = {
  [getterTypes.totalPages](state) {
    return state.staff.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.staff.total || 0;
  },

  [getterTypes.staffList](state) {
    return state.staff?.result || [];
  },
  [getterTypes.memberFullName](state) {
    const { first_name, last_name } = state.member;
    return `${first_name} ${last_name}`;
  },
  [getterTypes.isEmailConfirmed](state) {
    const confirmation = state.member.is_email_confirmed;
    return confirmation === undefined ? true : confirmation;
  },
  [getterTypes.firstNameErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "first_name__required") &&
      result.push(i18n.t("errors.firstNameRequired"));
    errors.find((i) => i == "first_name__invalid") &&
      result.push(i18n.t("errors.firstNameInvalid"));
    return result;
  },
  [getterTypes.lastNameErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "last_name__required") &&
      result.push(i18n.t("errors.lastNameRequired"));
    errors.find((i) => i == "last_name__invalid") &&
      result.push(i18n.t("errors.lastNameInvalid"));
    return result;
  },
  [getterTypes.emailErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i == "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    return result;
  },
  [getterTypes.roleErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "role__required") &&
      result.push(i18n.t("errors.roleRequired"));
    errors.find((i) => i == "role__invalid") &&
      result.push(i18n.t("errors.roleInvalid"));
    return result;
  },
  [getterTypes.organizationErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "organization__required") &&
      result.push(i18n.t("errors.organizationRequired"));
    errors.find((i) => i == "organization__invalid") &&
      result.push(i18n.t("errors.organizationInvalid"));
    return result;
  },
  [getterTypes.isSuspended](state) {
    return state.member.is_suspended;
  },

  [getterTypes.phoneErrors]({ errors }) {
    const result = [];
    errors.find((item) => item === "phone_number__required") &&
      result.push(i18n.t("errors.phoneRequired"));
    errors.find((item) => item === "phone_number__invalid") &&
      result.push(i18n.t("errors.phoneInvalid"));
    errors.find((item) => item === "phone_number__exists") &&
      result.push(i18n.t("errors.phoneExists"));
    errors.find((item) => item === "phone_code__invalid") &&
      result.push(i18n.t("errors.phoneCodeInvalid"));
    errors.find((item) => item === "customer__not_found") &&
      result.push(i18n.t("errors.customerNotFound"));
    errors.find((item) => item === "phone_number__absher_not_available") &&
      result.push(i18n.t("errors.phoneAbsherNotActivated"));
    errors.find((item) => item === "action__limit_exceeded") &&
      result.push(i18n.t("errors.actionLimit"));
    return result;
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
};
