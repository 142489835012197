import agencyApi from "@/api/agency";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isCreating: false,
  data: {},
  searchValue: "",
  errors: [],
  isEditing: false,
  isToggling: false,
  page: 1,
};

export const mutationTypes = {
  getAgentStart: "[agencyAgent] getAgentStart",
  getAgentFailed: "[agencyAgent] getAgentFailed",
  getAgentSuccess: "[agencyAgent] getAgentSuccess",

  changeSearchValue: "[agencyAgent] changeSearchValue",
  changePagination: "[agencyAgent] changePagination",

  createAgentStart: "[agencyAgent] createAgentStart",
  createAgentFailed: "[agencyAgent] createAgentFailed",
  createAgentSuccess: "[agencyAgent] createAgentSuccess",

  editAgentStart: "[agencyAgent] editAgentStart",
  editAgentFailed: "[agencyAgent] editAgentFailed",
  editAgentSuccess: "[agencyAgent] editAgentSuccess",

  toggleAgentStart: "[agencyAgent] toggleAgentStart",
  toggleAgentFailed: "[agencyAgent] toggleAgentFailed",
  toggleAgentSuccess: "[agencyAgent] toggleAgentSuccess",

  resetErrors: "[agencyAgent] resetErrors",
};

const mutations = {
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },
  [mutationTypes.changePagination](state, payload) {
    state.page = payload;
  },

  [mutationTypes.getAgentStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getAgentSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getAgentFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.createAgentStart](state) {
    state.isCreating = true;
    state.errors = [];
  },
  [mutationTypes.createAgentSuccess](state) {
    state.isCreating = false;
    state.errors = [];
  },
  [mutationTypes.createAgentFailed](state, payload) {
    state.isCreating = false;
    state.errors = payload;
  },

  [mutationTypes.editAgentStart](state) {
    state.isEditing = true;
    state.errors = [];
  },
  [mutationTypes.editAgentSuccess](state) {
    state.isEditing = false;
    state.errors = [];
  },
  [mutationTypes.editAgentFailed](state, payload) {
    state.isEditing = false;
    state.errors = payload;
  },

  [mutationTypes.toggleAgentStart](state) {
    state.isToggling = true;
    state.errors = [];
  },
  [mutationTypes.toggleAgentSuccess](state) {
    state.isToggling = false;
    state.errors = [];
  },
  [mutationTypes.toggleAgentFailed](state, payload) {
    state.isToggling = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getAgent: "[agencyAgent] getAgent",
  createAgent: "[agencyAgent] createAgent",
  editAgent: "[agencyAgent] editAgent",
  removeAgent: "[agencyAgent] removeAgent",
  restoreAgent: "[agencyAgent] restoreAgent",
  suspendAgent: "[agencyAgent] suspendAgent",
};

const actions = {
  async [actionTypes.getAgent](context, payload) {
    try {
      context.commit(mutationTypes.getAgentStart);
      const res = await agencyApi.getAgenciesAgentList(payload);
      context.commit(mutationTypes.getAgentSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAgentFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.createAgent](context, payload) {
    try {
      context.commit(mutationTypes.createAgentStart);
      await agencyApi.createAgenciesAgent(payload);
      context.commit(mutationTypes.createAgentSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.createAgentFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.editAgent](context, payload) {
    try {
      context.commit(mutationTypes.editAgentStart);
      await agencyApi.editAgenciesAgent(payload);
      context.commit(mutationTypes.editAgentSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.editAgentFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.removeAgent](context, payload) {
    try {
      context.commit(mutationTypes.toggleAgentStart);
      await agencyApi.removeAgenciesAgent(payload);
      context.commit(mutationTypes.toggleAgentSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.toggleAgentFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.suspendAgent](context, payload) {
    try {
      context.commit(mutationTypes.toggleAgentStart);
      await agencyApi.deactivateAgenciesAgent(payload);
      context.commit(mutationTypes.toggleAgentSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.toggleAgentFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.restoreAgent](context, payload) {
    try {
      context.commit(mutationTypes.toggleAgentStart);
      await agencyApi.activateAgenciesAgent(payload);
      context.commit(mutationTypes.toggleAgentSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.toggleAgentFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  agent: "[agencyAgent] agent",
  totalItems: "[agencyAgent] totalItems",
  totalPages: "[agencyAgent] totalPages",
  emailErrors: "[agencyAgent] emailErrors",
  firstNameErrors: "[agencyAgent] firstNameErrors",
  lastNameErrors: "[agencyAgent] lastNameErrors",
  roleErrors: "[agencyAgent] roleErrors",
  idErrors: "[agencyAgent] idErrors",
};

const getters = {
  [getterTypes.agent](state) {
    return state.data?.result || [];
  },
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },

  [getterTypes.idErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "id_number__required") &&
      result.push(i18n.t("errors.idRequired"));
    errors.find((i) => i == "id_number__invalid") &&
      result.push(i18n.t("errors.idInvalid"));
    errors.find((i) => i == "agent__invited") &&
      result.push(i18n.t("errors.agentInvited"));
    errors.find((i) => i == "agent__already_linked") &&
      result.push(i18n.t("errors.agentAlreadyLinked"));
    errors.find((i) => i == "agent__already_linked_another") &&
      result.push(i18n.t("errors.agentAlreadyLinkedAnother"));
    return result;
  },
  [getterTypes.firstNameErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "first_name__required") &&
      result.push(i18n.t("errors.firstNameRequired"));
    errors.find((i) => i == "first_name__invalid") &&
      result.push(i18n.t("errors.firstNameInvalid"));
    return result;
  },
  [getterTypes.lastNameErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "last_name__required") &&
      result.push(i18n.t("errors.lastNameRequired"));
    errors.find((i) => i == "last_name__invalid") &&
      result.push(i18n.t("errors.lastNameInvalid"));
    return result;
  },
  [getterTypes.emailErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i == "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    return result;
  },
  [getterTypes.roleErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "role__required") &&
      result.push(i18n.t("errors.roleRequired"));
    errors.find((i) => i == "role__invalid") &&
      result.push(i18n.t("errors.roleInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
