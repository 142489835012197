import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/notifications/";

let connectionHandlers = [];
let socket = null;

const getNotifications = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const setSeenAll = () => {
  return axios.post(`${urlPath}seen-all`);
};

const setSeenMessage = (id) => {
  return axios.post(`${urlPath}${id}/seen`);
};

const getNewMsgCount = () => {
  return axios.get(`${urlPath}count`);
};

function openWebSocket(token) {
  const url =
    process.env.NODE_ENV === "development"
      ? "local.api.holoul.amifactory.dev"
      : window.location.host;
  if (socket) {
    return;
  }
  socket = new WebSocket(`wss://${url}/platform/ws/v1/?access_token=${token}`);

  socket.addEventListener(
    "open",
    () => {
      console.log("OOOPPPEEEENNN");
    },
    { once: true }
  );

  socket.addEventListener("message", (e) => {
    const response = JSON.parse(e.data);
    if (response.event === "notification") {
      connectionHandlers[0](response);
    } else if (response.event === "message_update") {
      connectionHandlers[1](response);
    } else if (response.event === "conversation_update") {
      connectionHandlers[2](response);
    }
  });

  socket.addEventListener("close", () => {
    console.log("CLOSE WEBSOCKET CONNECTION");
  });
}

export const sendToWebsocket = (id, time) => {
  const stringifiedMessage = JSON.stringify({
    event: "conversation_update",

    payload: {
      id,
      last_message_seen_at: time,
    },
  });

  if (socket.readyState === WebSocket.OPEN) {
    socket.send(stringifiedMessage);
  }
};

function subscribeToNotificationsOnWs(token) {
  openWebSocket(token);
}

export const subscribeToNotifications = (token, cb) => {
  connectionHandlers = cb;
  subscribeToNotificationsOnWs(token);
};

export default {
  getNotifications,
  setSeenAll,
  setSeenMessage,
  getNewMsgCount,
};
