import notificationApi from "@/api/notification";

const state = {
  errors: [],
  isLoading: false,
  isReadingAll: false,
  data: {},
  ntfCount: 0,
  notificationsId: new Date(),
};

export const mutationTypes = {
  getNotificationsStart: "[notification] getNotificationsStart",
  getNotificationsFailed: "[notification] getNotificationsFailed",
  getNotificationsSuccess: "[notification] getNotificationsSuccess",

  readAllStart: "[notification] readAllStart",
  readAllFailed: "[notification] readAllFailed",
  readAllSuccess: "[notification] readAllSuccess",
  setNtfCount: "[notification] setNtfCount",
  changeNotificationsId: "[notification] changeNotificationsId",
};

const mutations = {
  [mutationTypes.changeNotificationsId](state) {
    state.notificationsId = state.notificationsId + 1;
  },
  [mutationTypes.setNtfCount](state, payload) {
    state.ntfCount = payload;
  },
  [mutationTypes.getNotificationsStart](state) {
    state.isLoading = true;
    state.errors = [];
    state.data = {};
  },
  [mutationTypes.getNotificationsSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getNotificationsFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.readAllStart](state) {
    state.isReadingAll = true;
    state.errors = [];
  },
  [mutationTypes.readAllSuccess](state) {
    state.isReadingAll = false;
    state.errors = [];
  },
  [mutationTypes.readAllFailed](state, payload) {
    state.isReadingAll = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getNotifications: "[notification] getNotifications",
  readAll: "[notification] readAll",
  readMessage: "[notification] readMessage",
  getNotificationsCount: "[notificaton] getNotificationsCount",
};

const actions = {
  async [actionTypes.getNotifications](context, payload) {
    try {
      context.commit(mutationTypes.getNotificationsStart);

      const res = await notificationApi.getNotifications(payload);
      context.commit(mutationTypes.getNotificationsSuccess, res.data);
      return res.data;
    } catch (e) {
      context.commit(
        mutationTypes.getNotificationsFailed,
        e.response.data.error
      );
      return false;
    }
  },
  async [actionTypes.readAll](context) {
    try {
      context.commit(mutationTypes.readAllStart);

      await notificationApi.setSeenAll();
      context.commit(mutationTypes.readAllSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.readAllFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.readMessage](context, payload) {
    try {
      await notificationApi.setSeenMessage(payload);
      return true;
    } catch (e) {
      return false;
    }
  },
  async [actionTypes.getNotificationsCount](context) {
    try {
      const res = await notificationApi.getNewMsgCount();
      context.commit(
        mutationTypes.setNtfCount,
        res.data.new_notifications_count
      );
      return true;
    } catch (e) {
      return false;
    }
  },
};
export const getterTypes = {
  notifications: "[notification] notifications",
  pages: "[notification] pages",
};

const getters = {
  [getterTypes.notifications](state) {
    return state.data.result || [];
  },
  [getterTypes.pages](state) {
    return state.data?.pages || 0;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
