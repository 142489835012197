import { toHijri } from "hijri-date/lib/safe";
import HijrahDate from "hijrah-date";

export const roundedNumber = (value = 0) => {
  return Math.round(value / 100);
};

export const toCents = (value) => {
  return Math.trunc(value * 100);
};

export const roundToHundredth = (value) => {
  return (value / 100).toFixed(2);
};

export const toHijriFormat = (date) => {
  const gregDate = new Date(date);
  return toHijri(gregDate).format("yyyy/mm/dd");
};

export const getGregorianDate = (hijriDate) => {
  if (!hijriDate) {
    return "";
  }
  const [year, month, day] = hijriDate.split("-");
  let yourDate = new HijrahDate(+year, +month - 1, +day).toGregorian();
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split("T")[0];
};

export const formattedDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString(
    'en-US',
    {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }
  );
};

export const formattedDateTime = (value) => {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${day} ${month}. ${year}, ${hour}:${minute.toString().padStart(2, '0')}`;
};

/**
 * @param timestamp in format 1688560292150
 *
 * @return String "May 24, 2023, 07:38"
 * */
export const formatTimestampDateTime = timestamp => {
    const date = new Date(timestamp);
    const options = {
        hour12: false,
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };

    return date.toLocaleDateString('en-US', options);
}

/**
 * @param islamicDate in format 03/1446
 *
 *@return String Jumada al-Thani 1444
 * */
export const convertIslamicDateToGregorian = islamicDate => {
  const monthNames = [
    'Muharram', 'Safar', 'Rabi\' al-Awwal', 'Rabi\' al-Thani',
    'Jumada al-Awwal', 'Jumada al-Thani', 'Rajab', 'Sha\'ban',
    'Ramadan', 'Shawwal', 'Dhu al-Qi\'dah', 'Dhu al-Hijjah'
  ];

  // Розділити ісламську дату на місяць та рік
  const parts = islamicDate.split('/');
  const islamicMonth = parseInt(parts[0]);
  const islamicYear = parseInt(parts[1]);

  // Знайти відповідну назву місяця
  const monthName = monthNames[islamicMonth - 1];

  // Додати рік
  const gregorianYear = 622 + islamicYear;

  // Повернути сформований рядок
  return `${monthName} ${gregorianYear}`;
}

export const dateToSendBackend = (date) => {
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();

  return yyyy + '-' + mm + '-' + dd;
}