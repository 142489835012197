import axios from "@/plugins/axios";

const apiUrl = "/platform/api/v1/projects/";

const getProjectsList = (params) => {
  return axios.get(`${apiUrl}?${params}`);
};

const getProject = (id) => {
  return axios.get(`${apiUrl}${id}`);
};

const getProjectUnits = (id, data) => {
  return axios.get(`${apiUrl}${id}/units/${data ? data : ''}`);
};

export default {
  getProjectsList,
  getProject,
  getProjectUnits,
};
