<template>
  <div class="empty-layout d-flex align-center justify-center">
    <notifications></notifications>
    <v-btn
      height="40"
      color="primary"
      class="lang-btn mx-4 radius-34 btn-shadow"
      @click="changeLang"
    >
      <div class="d-flex align-center">
        <v-icon size="24">mdi-web</v-icon>
        <span class="text-body-1 bold btn-text ps-3">{{ $t(`${$i18n.locale == 'en' ? 'ar' : 'en'}`) }}</span>
      </div>
    </v-btn>
    <div class="flex-grow-1 d-flex align-center py-5">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.getElementById("html").style = "";
  },
  mounted() {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    this.$i18n.locale = lang;
    this.$vuetify.locale = lang;
    this.$vuetify.rtl = lang == 'ar';
  },
  methods: {
    changeLang () {
      const item = this.$i18n.locale == 'en' ? 'ar' : 'en';
      this.$vuetify.locale = item;
      this.$vuetify.rtl = item == 'ar';
      this.$i18n.locale = item;
      localStorage.setItem('lang', item);
    }
  },
};
</script>
<style lang="scss">
.empty-layout {
  min-height: 100vh;
  background: url(../assets/images/auth-background.svg);
  background-position: center;
  background-size: cover;
  position: relative;
  .lang-btn {
    position: absolute;
    top: 20px;
    right: 0;
  }
}
.v-application--is-rtl {
  .lang-btn {
    right: initial;
    left: 0;
  }
}
</style>
