<template>
  <div>
    <div :class="{'is-empty': !(isMessageLoading || msgList?.length)}" class="wefwefw">
    <div class="d-flex align-center ntf-btn">
      <v-btn elevation="0" icon color="gray" @click="ntfModal = !ntfModal">
        <v-img height="24" max-width="24" :src="bellIcon"></v-img>
        <v-sheet
          v-if="ntfCount"
          :elevation="0"
          height="10"
          width="10"
          color="red-2"
          rounded="circle"
          class="ntf-circle"
        ></v-sheet>
      </v-btn>
    </div>
    </div>
      <v-menu v-model="ntfModal" :nudge-width="560" offset-x :attach="'.ntf-btn'">
        <v-card>
          <div
              @click.stop
              class="d-flex align-center justify-space-between px-4 py-4"
          >
            <h3 class="font-weight-black f-17">{{ $t("Notifications") }}</h3>
            <v-btn
              v-if="msgList?.length"
              text
              color="light-blue"
              :loading="isReadingAll"
              class="btn-loader--blue"
              @click="onReadAll"
            >
            <span class="btn-text font-weight-bold f-16">{{
                $t("Read all")
              }}</span>
            </v-btn>
          </div>

          <v-divider></v-divider>

          <v-list v-if="isMessageLoading || msgList?.length" :elevation="0" class="ntf-list">
            <v-list-item v-if="isMessageLoading" class="d-flex justify-center">
              <v-progress-circular indeterminate color="light-blue">
              </v-progress-circular>
            </v-list-item>
            <template v-for="(item, index) in msgList">
              <v-list-item
                  :key="item.id"
                  link
                  class="px-4 py-2 relative"
                  @click="onMessageClick(item)"
              >
                <div>
                  <p>
                    {{
                      $t(item.data.type, {
                        id: item.data.financing_request.number,
                      })
                    }}
                  </p>
                  <p class="gray-3--text">{{ submittedAt(item.date) }}</p>
                  <v-sheet
                      v-if="!item.seen"
                      :elevation="0"
                      height="10"
                      width="10"
                      color="blue"
                      rounded="circle"
                      class="unread-circle"
                  ></v-sheet>
                </div>
              </v-list-item>

              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
          <div v-else class="width100 mt-6">
            <p class="text-center text--text gray-3--text f-16">
              {{ $t("You don’t have any notifications")}}
            </p>
          </div>
        </v-card>
        <infinite-loading
            :identifier="infiniteId"
            @infinite="infiniteHandler"
            force-use-infinite-wrapper=".v-menu__content"
        >
          <template v-slot:no-results>{{ "" }}</template>
          <template v-slot:no-more>{{ "" }}</template>
        </infinite-loading>
      </v-menu>
    </div>
</template>
<script>
import bellIcon from "@/assets/icons/bell.svg";
import { mapGetters, mapState } from "vuex";
import { actionTypes, getterTypes } from "@/store/modules/notification";
import { formattedDateTime } from "@/helpers/numeric";
import queryString from "query-string";

export default {
  data() {
    return {
      bellIcon,
      msgList: [],
      ntfModal: false,
      page: 0,
      infiniteId: +new Date(),
      menuRef: null,
    };
  },
  mounted() {
    this.updateNotifications();
  },

  methods: {
    getQueryParams(page = this.page) {
      const params = queryString.stringify({
        page,
      });

      return params;
    },
    async infiniteHandler($state) {
      if (this.page + 1 <= this.pages) {
        await this.loadMessages();
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    async onReadAll() {
      await this.$store.dispatch(actionTypes.readAll);
      this.resetMsgList();
    },
    async onMessageClick(msg) {
      const id = msg.data.financing_request.number;
      this.$router.push(`/requests/${id}`);

      await this.$store.dispatch(actionTypes.readMessage, msg.id);
      this.resetMsgList();
    },
    submittedAt(date) {
      return formattedDateTime(date);
    },
    async loadMessages() {
      const res = await this.$store.dispatch(
        actionTypes.getNotifications,
        this.getQueryParams()
      );
      if (res) {
        this.page = this.page + 1;
        this.msgList.push(...res.result);
      }
    },
    updateNotifications() {
      this.loadMessages();
      this.$store.dispatch(actionTypes.getNotificationsCount);
    },
    async resetMsgList() {
      const res = await this.$store.dispatch(
        actionTypes.getNotifications,
        this.getQueryParams(0)
      );
      if (res) {
        this.page = 1;
        this.msgList = res.result;
        this.infiniteId += 1;
      }
      this.$store.dispatch(actionTypes.getNotificationsCount);
    },
  },

  computed: {
    ...mapState({
      ntfCount: (state) => state.notification.ntfCount,
      isReadingAll: (state) => state.notification.isReadingAll,
      isMessageLoading: (state) => state.notification.isLoading,
      notificationsId: (state) => state.notification.notificationsId,
    }),
    ...mapGetters({
      notifications: getterTypes.notifications,
      pages: getterTypes.pages,
    }),
  },

  watch: {
    ntfModal(newValue) {
      if (this.menuRef && !newValue) {
        this.menuRef.scrollTop = 0;
        return;
      }
      if (!newValue) {
        const listElm = document.querySelector(".ntf-btn");
        this.menuRef = listElm.querySelector(".v-menu__content");
        this.menuRef.scrollTop = 0;
      }
    },
    notificationsId() {
      this.resetMsgList();
    },
  },
};
</script>
<style lang="scss">
.msg-wrapper {
  height: 400px;
  overflow: auto;
}
.unread-circle {
  position: absolute !important;
  bottom: 12px;
  right: 12px;
}

.ntf-btn {
  & .v-menu__content {
    position: fixed !important;
    left: unset !important;
    right: 24px;
    top: 70px !important;
    text-transform: initial !important;
    height: 500px !important;
    max-width: 560px;
    width: 100%;
    background-color: white;
    border-radius: 16px !important;
    overflow: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .v-list {
    padding: 0 !important;
  }

  & .v-list-item {
    padding: 8px 16px !important;
  }
}

.is-empty {
  .ntf-btn .v-menu__content {
    height: 142px !important;
  }
}

.ntf-circle {
  position: absolute;
  right: 8px;
  top: 2px;
}

.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
