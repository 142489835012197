import api from "@/api/fab";
import { i18n } from "@/plugins/i18n";

const state = {
  errors: [],
  list: [],
  item: {},
  searchValue: "",
  isSubmitting: false,
  isLoading: false,
};

export const mutationTypes = {
  getFabListStart: "[fab] getFabListStart",
  getFabListFailed: "[fab] getFabListFailed",
  getFabListSuccess: "[fab] getFabListSuccess",

  createStart: "[fab] createStart",
  createFailed: "[fab] createFailed",
  createSuccess: "[fab] createSuccess",

  getFabStart: "[fab] getFabStart",
  getFabFailed: "[fab] getFabFailed",
  getFabSuccess: "[fab] getFabSuccess",

  updateFabStart: "[fab] updateFabStart",
  updateFabFailed: "[fab] updateFabFailed",
  updateFabSuccess: "[fab] updateFabSuccess",

  removeFabProcess: "[fab] removeFabProcess",
  removeFabFailed: "[fab] removeFabFailed",

  changeSearchValue: "[fab] changeSearchValue",
  resetErrors: "[fab] resetErrors",
};

const mutations = {
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },

  [mutationTypes.getFabListStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getFabListSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.list = payload;
  },
  [mutationTypes.getFabListFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.createStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.createSuccess](state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  [mutationTypes.createFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.getFabStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getFabSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.item = payload;
  },
  [mutationTypes.getFabFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.updateFabStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.updateFabSuccess](state, payload) {
    state.isSubmitting = false;
    state.errors = [];
    state.item = payload;
  },
  [mutationTypes.updateFabFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.removeFabProcess](state) {
    state.errors = [];
  },
  [mutationTypes.removeFabFailed](state, payload) {
    state.errors = payload;
  },
};

export const actionTypes = {
  getFabList: "[fab] getFabList",
  createFab: "[fab] createFab",
  getFab: "[fab] getFab",
  updateFab: "[fab] updateFab",
  removeFab: "[fab] removeFab",
};

const actions = {
  async [actionTypes.getFabList](context, payload) {
    try {
      context.commit(mutationTypes.getFabListStart);
      const res = await api.getFabList(payload);
      context.commit(mutationTypes.getFabListSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getFabListFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.createFab](context, payload) {
    try {
      context.commit(mutationTypes.createStart);
      const res = await api.addFab(payload);
      context.commit(mutationTypes.createSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.createFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getFab](context, payload) {
    try {
      context.commit(mutationTypes.getFabStart);
      const res = await api.getFabItem(payload);
      context.commit(mutationTypes.getFabSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getFabFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateFab](context, { id, data }) {
    try {
      context.commit(mutationTypes.updateFabStart);

      const res = await api.updateFabItem(id, data);
      context.commit(mutationTypes.updateFabSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateFabFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.removeFab](context, payload) {
    try {
      context.commit(mutationTypes.removeFabProcess);

      await api.removeFabItem(payload);
      return true;
    } catch (e) {
      context.commit(mutationTypes.removeFabFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  salaryTransferErrors: "[fab] salaryTransferErrors",
  redfSupportErrors: "[fab] redfSupportErrors",
  rateFlatErrors: "[fab] rateFlatErrors",
  loanDurationMonthsMaxErrors: "[fab] loanDurationMonthsMaxErrors",
};

const getters = {
  [getterTypes.salaryTransferErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "salary_transfer__required") &&
      result.push(i18n.t("errors.salaryTransferRequired"));
    errors.find((i) => i == "salary_transfer__invalid") &&
      result.push(i18n.t("errors.salaryTransferInvalid"));
    return result;
  },
  [getterTypes.redfSupportErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "redf_support__required") &&
      result.push(i18n.t("errors.redfSupportRequired"));
    errors.find((i) => i == "redf_support__invalid") &&
      result.push(i18n.t("errors.redfSupportInvalid"));
    return result;
  },
  [getterTypes.rateFlatErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "rate_flat__required") &&
      result.push(i18n.t("errors.rateFlatRequired"));
    errors.find((i) => i == "rate_flat__invalid") &&
      result.push(i18n.t("errors.rateFlatInvalid"));
    return result;
  },
  [getterTypes.loanDurationMonthsMaxErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "loan_duration_months_max__required") &&
      result.push(i18n.t("errors.loanDurationMonthsMaxRequired"));
    errors.find((i) => i == "loan_duration_months_max__invalid") &&
      result.push(i18n.t("errors.loanDurationMonthsMaxInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
