import organizationApi from "@/api/organization";
import { i18n } from "@/plugins/i18n";

const state = {
  errors: [],
  isLoading: false,
  data: [],
  isSubmitting: false,
  bank: {},
  isActivating: false,
  isDeactivating: false,
  staffList: [],
  staffItem: {},
  typeList: [
    { name: 'generic', title: i18n.t("orgTypeList.generic") },
    { name: 'bidaya', title: i18n.t("orgTypeList.bidaya") },
    { name: 'fab', title: i18n.t("orgTypeList.fab") },
  ]
};

export const mutationTypes = {
  getOrganizationsStart: "[organization] getOrganizationsStart",
  getOrganizationsFailed: "[organization] getOrganizationsFailed",
  getOrganizationsSuccess: "[organization] getOrganizationsSuccess",

  createStart: "[organization] createStart",
  createFailed: "[organization] createFailed",
  createSuccess: "[organization] createSuccess",

  getBankStart: "[organization] getBankStart",
  getBankFailed: "[organization] getBankFailed",
  getBankSuccess: "[organization] getBankSuccess",

  activateStart: "[organization] activateStart",
  activateFailed: "[organization] activateFailed",
  activateSuccess: "[organization] activateSuccess",
  deactivateStart: "[organization] deactivateStart",
  deactivateFailed: "[organization] deactivateFailed",
  deactivateSuccess: "[organization] deactivateSuccess",

  updateBankStart: "[organization] updateBankStart",
  updateBankFailed: "[organization] updateBankFailed",
  updateBankSuccess: "[organization] updateBankSuccess",

  getOrganizationsStaffListStart: "[organization] getOrganizationsStaffListStart",
  getOrganizationsStaffListFailed: "[organization] getOrganizationsStaffListFailed",
  getOrganizationsStaffListSuccess: "[organization] getOrganizationsStaffListSuccess",

  actionStaffItemStart: "[organization] actionStaffItemStart",
  actionStaffItemFailed: "[organization] actionStaffItemFailed",
  actionStaffItemSuccess: "[organization] actionStaffItemSuccess",

  resetErrors: "[organization] resetErrors",
};

const mutations = {
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },
  [mutationTypes.getOrganizationsStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getOrganizationsSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getOrganizationsFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.createStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.createSuccess](state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  [mutationTypes.createFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.getBankStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getBankSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.bank = payload;
  },
  [mutationTypes.getBankFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.activateStart](state) {
    state.isActivating = true;
    state.errors = [];
  },
  [mutationTypes.activateSuccess](state, payload) {
    state.isActivating = false;
    state.bank = payload;
    state.errors = [];
  },
  [mutationTypes.activateFailed](state, payload) {
    state.isActivating = false;
    state.errors = payload;
  },
  [mutationTypes.deactivateStart](state) {
    state.isDeactivating = true;
    state.errors = [];
  },
  [mutationTypes.deactivateSuccess](state, payload) {
    state.isDeactivating = false;
    state.bank = payload;
    state.errors = [];
  },
  [mutationTypes.deactivateFailed](state, payload) {
    state.isDeactivating = false;
    state.errors = payload;
  },

  [mutationTypes.updateBankStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.updateBankSuccess](state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  [mutationTypes.updateBankFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.getOrganizationsStaffListStart](state) {
    state.errors = [];
  },
  [mutationTypes.getOrganizationsStaffListSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.staffList = payload;
  },
  [mutationTypes.getOrganizationsStaffListFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.actionStaffItemStart](state) {
    state.errors = [];
  },
  [mutationTypes.actionStaffItemSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.staffItem = payload;
  },
  [mutationTypes.actionStaffItemFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getOrganizations: "[organization] getOrganizations",
  createOrganization: "[organization] createOrganization",
  getBank: "[organization] getBank",
  activateBank: "[organization] activateBank",
  deactivateBank: "[organization] deactivateBank",
  updateBank: "[organization] updateBank",
  updateLogo: "[organization] updateLogo",
  getOrganizationsStaffList: "[organization] getOrganizationsStaffList",
  createOrganizationsStaff: "[organization] createOrganizationsStaff",
  getOrganizationsStaff: "[organization] getOrganizationsStaff",
  updateOrganizationsStaff: "[organization] updateOrganizationsStaff",
  resendEmailOrganizationsStaff: "[organization] resendEmailOrganizationsStaff",
  resetPassOrganizationsStaff: "[organization] resetPassOrganizationsStaff",
  suspendOrganizationsStaff: "[organization] suspendOrganizationsStaff",
  restoreOrganizationsStaff: "[organization] restoreOrganizationsStaff",
};

const actions = {
  async [actionTypes.getOrganizations](context, payload) {
    try {
      context.commit(mutationTypes.getOrganizationsStart);

      const res = await organizationApi.getOrganizations(payload);
      context.commit(mutationTypes.getOrganizationsSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(
        mutationTypes.getOrganizationsFailed,
        e.response.data.error
      );
      return false;
    }
  },

  async [actionTypes.createOrganization](context, payload) {
    try {
      context.commit(mutationTypes.createStart);

      await organizationApi.createOrganization(payload);
      context.commit(mutationTypes.createSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.createFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getBank](context, payload) {
    try {
      context.commit(mutationTypes.getBankStart);

      const res = await organizationApi.getBank(payload);
      context.commit(mutationTypes.getBankSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getBankFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.activateBank](context, payload) {
    try {
      context.commit(mutationTypes.activateStart);

      const res = await organizationApi.activateBank(payload);
      context.commit(mutationTypes.activateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.activateFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.deactivateBank](context, payload) {
    try {
      context.commit(mutationTypes.deactivateStart);

      const res = await organizationApi.deactivateBank(payload);
      context.commit(mutationTypes.deactivateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.deactivateFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateBank](context, { id, data }) {
    try {
      context.commit(mutationTypes.updateBankStart);

      await organizationApi.updateBank(id, data);
      context.commit(mutationTypes.updateBankSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateBankFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateLogo](context, { id, data }) {
    try {
      await organizationApi.updateLogo(id, data);
      return true;
    } catch (e) {
      return false;
    }
  },

  async [actionTypes.getOrganizationsStaffList](context, { id, data }) {
    try {
      context.commit(mutationTypes.getOrganizationsStaffListStart);

      const res = await organizationApi.getOrganizationsStaffList(id, data);
      context.commit(mutationTypes.getOrganizationsStaffListSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(
        mutationTypes.getOrganizationsStaffListFailed,
        e.response.data.error
      );
      return false;
    }
  },

  async [actionTypes.createOrganizationsStaff](context, { id, data }) {
    try {
      context.commit(mutationTypes.actionStaffItemStart);

      const res = await organizationApi.createOrganizationsStaff(id, data);
      context.commit(mutationTypes.actionStaffItemSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(
        mutationTypes.actionStaffItemFailed,
        e.response.data.error
      );
      return false;
    }
  },

  async [actionTypes.getOrganizationsStaff](context, { id, item }) {
    try {
      context.commit(mutationTypes.actionStaffItemStart);

      const res = await organizationApi.getOrganizationsStaff(id, item);
      context.commit(mutationTypes.actionStaffItemSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(
        mutationTypes.actionStaffItemFailed,
        e.response.data.error
      );
      return false;
    }
  },

  async [actionTypes.updateOrganizationsStaff](context, { id, item, data }) {
    try {
      context.commit(mutationTypes.actionStaffItemStart);

      const res = await organizationApi.updateOrganizationsStaff(id, item, data);
      context.commit(mutationTypes.actionStaffItemSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(
        mutationTypes.actionStaffItemFailed,
        e.response.data.error
      );
      return false;
    }
  },

  async [actionTypes.suspendOrganizationsStaff](context, { id, item }) {
    try {
      context.commit(mutationTypes.actionStaffItemStart);

      const res = await organizationApi.suspendOrganizationsStaff(id, item);
      context.commit(mutationTypes.actionStaffItemSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(
        mutationTypes.actionStaffItemFailed,
        e.response.data.error
      );
      return false;
    }
  },

  async [actionTypes.restoreOrganizationsStaff](context, { id, item }) {
    try {
      context.commit(mutationTypes.actionStaffItemStart);

      const res = await organizationApi.restoreOrganizationsStaff(id, item);
      context.commit(mutationTypes.actionStaffItemSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(
        mutationTypes.actionStaffItemFailed,
        e.response.data.error
      );
      return false;
    }
  },

  async [actionTypes.resendEmailOrganizationsStaff](context, { id, item }) {
    try {
      await organizationApi.resendEmailOrganizationsStaff(id, item);
      return true;
    } catch (e) {
      return false;
    }
  },

  async [actionTypes.resetPassOrganizationsStaff](context, { id, item }) {
    try {
      await organizationApi.resetPassOrganizationsStaff(id, item);
      return true;
    } catch (e) {
      return false;
    }
  },
};
export const getterTypes = {
  titleErrors: "[organization] titleErrors",
  firstNameErrors: "[organization] firstNameErrors",
  lastNameErrors: "[organization] lastNameErrors",
  emailErrors: "[organization] emailErrors",
  roleErrors: "[organization] roleErrors",
  phoneErrors: "[organization] phoneErrors",
  typeErrors: "[organization] typeErrors",
};

const getters = {
  [getterTypes.titleErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "title__required") &&
      result.push(i18n.t("errors.titleRequired"));
    errors.find((i) => i == "title__invalid") &&
      result.push(i18n.t("errors.titleInvalid"));
    return result;
  },
  [getterTypes.firstNameErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "first_name__required") &&
      result.push(i18n.t("errors.firstNameRequired"));
    errors.find((i) => i == "first_name__invalid") &&
      result.push(i18n.t("errors.firstNameInvalid"));
    return result;
  },
  [getterTypes.lastNameErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "last_name__required") &&
      result.push(i18n.t("errors.lastNameRequired"));
    errors.find((i) => i == "last_name__invalid") &&
      result.push(i18n.t("errors.lastNameInvalid"));
    return result;
  },
  [getterTypes.emailErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i == "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    return result;
  },
  [getterTypes.roleErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "role__required") &&
      result.push(i18n.t("errors.roleRequired"));
    errors.find((i) => i == "role__invalid") &&
      result.push(i18n.t("errors.roleInvalid"));
    return result;
  },
  [getterTypes.phoneErrors]({ errors }) {
    const result = [];
    errors.find((item) => item === "phone_number__required") &&
      result.push(i18n.t("errors.phoneRequired"));
    errors.find((item) => item === "phone_number__invalid") &&
      result.push(i18n.t("errors.phoneInvalid"));
    errors.find((item) => item === "phone_number__exists") &&
      result.push(i18n.t("errors.phoneExists"));
    errors.find((item) => item === "phone_code__invalid") &&
      result.push(i18n.t("errors.phoneCodeInvalid"));
    errors.find((item) => item === "customer__not_found") &&
      result.push(i18n.t("errors.customerNotFound"));
    errors.find((item) => item === "phone_number__absher_not_available") &&
      result.push(i18n.t("errors.phoneAbsherNotActivated"));
    errors.find((item) => item === "action__limit_exceeded") &&
      result.push(i18n.t("errors.actionLimit"));
    return result;
  },
  [getterTypes.typeErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "type__required") &&
      result.push(i18n.t("errors.typeRequired"));
    errors.find((i) => i == "type__invalid") &&
      result.push(i18n.t("errors.typeInvalid"));
    errors.find((i) => i == "type__already_exists") &&
      result.push(i18n.t("errors.typeExists"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
