import axios from "@/plugins/axios";

const apiUrl = "/platform/api/v1/agents/";

const getAgents = (params) => {
  return axios.get(`${apiUrl}?${params}`);
};

const getAgent = (id) => {
  return axios.get(`${apiUrl}${id}`);
};

const activateAgent = (id) => {
  return axios.post(`${apiUrl}${id}/activate`);
};

const deactivateAgent = (id) => {
  return axios.post(`${apiUrl}${id}/deactivate`);
};

const toggleAgencyAgent = ({ id, data }) => {
  return axios.post(`${apiUrl}${id}/agency`, data);
};

const assignPropertiesAgent = ({ id, data }) => {
  return axios.post(`${apiUrl}${id}/properties`, data);
};

const getAgentsList = (params) => {
  return axios.get(`${apiUrl}short/?${params}`);
};

export default {
  getAgents,
  getAgent,
  activateAgent,
  deactivateAgent,
  toggleAgencyAgent,
  assignPropertiesAgent,
  getAgentsList,
};
