import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/organizations/";

const getOrganizations = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const createOrganization = (data) => {
  return axios.post(`${urlPath}`, data);
};

const getBank = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const activateBank = (id) => {
  return axios.post(`${urlPath}${id}/activate`);
};

const deactivateBank = (id) => {
  return axios.post(`${urlPath}${id}/deactivate`);
};

const updateBank = (id, data) => {
  return axios.put(`${urlPath}${id}`, data);
};

const updateLogo = (id, data) => {
  return axios.post(`${urlPath}${id}/logo`, data);
};

const getOrganizationsStaffList = (id, data) => {
  return axios.get(`${urlPath}${id}/staff/?${data}`);
};

const createOrganizationsStaff = (id, data) => {
  return axios.post(`${urlPath}${id}/staff/`, data);
};

const getOrganizationsStaff = (id, item) => {
  return axios.get(`${urlPath}${id}/staff/${item}`);
};

const updateOrganizationsStaff = (id, item, data) => {
  return axios.put(`${urlPath}${id}/staff/${item}`, data);
};

const resendEmailOrganizationsStaff = (id, item) => {
  return axios.post(`${urlPath}${id}/staff/${item}/send-email-confirmation`);
};

const resetPassOrganizationsStaff = (id, item) => {
  return axios.post(`${urlPath}${id}/staff/${item}/reset-password`);
};

const suspendOrganizationsStaff = (id, item) => {
  return axios.post(`${urlPath}${id}/staff/${item}/suspend`);
};

const restoreOrganizationsStaff = (id, item) => {
  return axios.post(`${urlPath}${id}/staff/${item}/restore`);
};

export default {
  getOrganizations,
  createOrganization,
  getBank,
  activateBank,
  deactivateBank,
  updateBank,
  updateLogo,
  getOrganizationsStaffList,
  createOrganizationsStaff,
  getOrganizationsStaff,
  updateOrganizationsStaff,
  resendEmailOrganizationsStaff,
  resetPassOrganizationsStaff,
  suspendOrganizationsStaff,
  restoreOrganizationsStaff,
};
