<template>
  <div class="header">
    <div
      class="d-flex flex-wrap align-center height100 width100 px-md-6 justify-space-between header"
    >
      <v-img :src="logoIcon" max-width="112" height="54" />
      <v-spacer></v-spacer>
      <img
        v-if="data.organization"
        src="@/assets/icons/organization-full.svg"
        alt="organization"
        width="24"
        height="24"
      />
      <div class="f-17 ps-2 pe-8" v-if="data.organization">{{ data.organization.title }}</div>
      <v-sheet v-if="data.organization" :elevation="0" height="32" width="1" color="gray-5"></v-sheet>
      <v-expand-transition>
        <Messages class="mx-6" />
      </v-expand-transition>

      <v-sheet :elevation="0" height="32" width="1" color="gray-5"></v-sheet>
      <v-menu offset-y nudge-bottom="10">
        <template v-slot:activator="{ on, attrs, value }">
          <div v-bind="attrs" v-on="on" class="header__menu ms-8">
            <v-skeleton-loader
              v-if="isLoading"
              type="avatar, sentences"
              class="header__skeleton d-flex align-center"
            ></v-skeleton-loader>
            <div v-else class="d-flex align-center">
              <v-avatar size="40" color="gray lighten-2">
                <v-img
                  v-if="data.picture"
                  cover
                  :src="data.picture"
                  alt="avatar"
                ></v-img>
                <v-img
                  v-else
                  cover
                  :src="emptyUser"
                  max-width="40"
                  height="40"
                  alt="avatar"
                ></v-img>
              </v-avatar>
              <div class="ms-4">
                <div class="font-weight-medium">
                  {{ data.first_name }} {{ data.last_name }}
                </div>
                <div class="text-body-2 gray-2--text text-capitalize">
                  {{ $t(data.role) }}
                </div>
              </div>
              <v-img :src="dropdown" :class="{ 'rotate-180' : value }" class="ms-4" max-width="24" height="24" />
            </div>
          </div>
        </template>
        <v-list class="list-wrapper" elevation="0">
          <v-list-item link to="/profile">
            <v-list-item-icon>
              <v-img :src="userIcon" :max-width="24" height="24" />
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Profile") }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="changeLang">
            <v-list-item-icon>
              <v-icon size="24" color="black">mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('btn.switch') }}
              {{ $t(`${$i18n.locale == 'en' ? 'ar' : 'en'}`) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="onLogout">
            <v-list-item-icon>
              <v-img :src="logout" :max-width="24" height="24" />
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import logoIcon from "@/assets/icons/logo.svg";
import emptyUser from "@/assets/icons/empty-user.svg";
import dropdown from "@/assets/icons/dropdown.svg";
import userIcon from "@/assets/icons/user-filled.svg";
import logout from "@/assets/icons/logout.svg";
import { actionTypes, mutationTypes  } from "@/store/modules/profile";

import { actionTypes as authActionTypes } from "@/store/modules/auth";
import { mapState } from "vuex";
import Messages from "@/components/shared/Messages.vue";

export default {
  components: { Messages },
  data() {
    return {
      logoIcon,
      emptyUser,
      dropdown,
      userIcon,
      logout,
      drawer: false,
    };
  },
  mounted() {
    this.$store.dispatch(actionTypes.getProfile);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(authActionTypes.logout);
      this.$store.commit(mutationTypes.resetProfile);
    },
    changeLang () {
      const item = this.$i18n.locale == 'en' ? 'ar' : 'en';
      this.$vuetify.locale = item;
      this.$vuetify.rtl = item == 'ar';
      this.$i18n.locale = item;
      localStorage.setItem('lang', item);
    }
  },
  computed: {
    ...mapState({
      data: (state) => state.profile.profile,
      isLoading: (state) => state.profile.isLoading,
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
  },
};
</script>

<style lang="scss" scoped>
.arrow-image {
  transition: transform 0.3s;
}
.header {
  height: 64px;
  border-bottom: 1px solid #e5e5e5;
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  background: white;
  position: sticky;
  z-index: 20;
  top: 0;

  &__menu[aria-expanded="true"] .arrow-image {
    transform: rotate(180deg);
  }

  &__skeleton {
    width: 200px;
  }
}
</style>
