import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "@/plugins/i18n";
import { vuetify } from "@/plugins/vuetify";
import titlePlugin from "@/plugins/title";
import "@/assets/styles/main.scss";
import Notifications from "@/components/NotificationPlugin";
import VueMeta from "vue-meta";
import VueClipboard from "vue-clipboard2";
import VCalendar from "v-calendar";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import InfiniteLoading from "vue-infinite-loading";

import VueEasyLightbox from "vue-easy-lightbox";
Vue.use(VueEasyLightbox);
Vue.use(InfiniteLoading, {});

Vue.use(Toast);
Vue.use(VueMeta);
Vue.use(titlePlugin);
Vue.use(Notifications);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VCalendar, {
  componentPrefix: "vc",
});

export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
