import productApi from "@/api/product";
import { itemsPerPage } from "@/helpers/paginations";
import { i18n } from "@/plugins/i18n";

const state = {
  errors: [],
  isLoading: false,
  isSubmitting: false,
  data: {},
  product: {},
  searchValue: "",
  page: 1,
  itemsPerPage: itemsPerPage,
  isActivating: false,
  isDeactivating: false,
  isArchiving: false,
};

export const mutationTypes = {
  getProductsStart: "[product] getProductsStart",
  getProductsFailed: "[product] getProductsFailed",
  getProductsSuccess: "[product] getProductsSuccess",

  getProductStart: "[product] getProductStart",
  getProductFailed: "[product] getProductFailed",
  getProductSuccess: "[product] getProductSuccess",

  resetErrors: "[product] resetErrors",

  changeSearchValue: "[product] changeSearchValue",
  changePagination: "[product] changePagination",

  createProductStart: "[product] createProductStart",
  createProductFailed: "[product] createProductFailed",
  createProductSuccess: "[product] createProductSuccess",

  activateStart: "[product] activateStart",
  activateFailed: "[product] activateFailed",
  activateSuccess: "[product] activateSuccess",
  deactivateStart: "[product] deactivateStart",
  deactivateFailed: "[product] deactivateFailed",
  deactivateSuccess: "[product] deactivateSuccess",

  updateProductStart: "[product] updateProductStart",
  updateProductFailed: "[product] updateProductFailed",
  updateProductSuccess: "[product] updateProductSuccess",

  archiveStart: "[product] archiveStart",
  archiveFailed: "[product] archiveFailed",
  archiveSuccess: "[product] archiveSuccess",
};

const mutations = {
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },
  [mutationTypes.getProductsStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getProductsSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getProductsFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getProductStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getProductSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.product = payload;
  },
  [mutationTypes.getProductFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.createProductStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.createProductSuccess](state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  [mutationTypes.createProductFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.activateStart](state) {
    state.isActivating = true;
    state.errors = [];
  },
  [mutationTypes.activateSuccess](state, payload) {
    state.isActivating = false;
    state.product = payload;
    state.errors = [];
  },
  [mutationTypes.activateFailed](state, payload) {
    state.isActivating = false;
    state.errors = payload;
  },
  [mutationTypes.deactivateStart](state) {
    state.isDeactivating = true;
    state.errors = [];
  },
  [mutationTypes.deactivateSuccess](state, payload) {
    state.isDeactivating = false;
    state.product = payload;
    state.errors = [];
  },
  [mutationTypes.deactivateFailed](state, payload) {
    state.isDeactivating = false;
    state.errors = payload;
  },

  [mutationTypes.updateProductStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.updateProductSuccess](state, payload) {
    state.isSubmitting = false;
    state.product = payload;
    state.errors = [];
  },
  [mutationTypes.updateProductFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.archiveStart](state) {
    state.isArchiving = true;
    state.errors = [];
  },
  [mutationTypes.archiveSuccess](state) {
    state.isArchiving = false;
    state.errors = [];
  },
  [mutationTypes.archiveFailed](state, payload) {
    state.isArchiving = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getProducts: "[product] getProducts",
  getProduct: "[product] getProduct",
  createProduct: "[product] createProduct",
  activateProduct: "[product] activateProduct",
  deactivateProduct: "[product] deactivateProduct",
  updateProduct: "[product] updateProduct",
  archiveProduct: "[product] archiveProduct",
};

const actions = {
  async [actionTypes.getProducts](context, payload) {
    try {
      context.commit(mutationTypes.getProductsStart);

      const res = await productApi.getProducts(payload);
      context.commit(mutationTypes.getProductsSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getProductsFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getProduct](context, payload) {
    try {
      context.commit(mutationTypes.getProductStart);

      const res = await productApi.getProduct(payload);
      context.commit(mutationTypes.getProductSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getProductFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.createProduct](context, payload) {
    try {
      context.commit(mutationTypes.createProductStart);

      const res = await productApi.createProduct(payload);
      context.commit(mutationTypes.createProductSuccess);
      return res.data.id;
    } catch (e) {
      context.commit(mutationTypes.createProductFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.activateProduct](context, payload) {
    try {
      context.commit(mutationTypes.activateStart);

      const res = await productApi.activateProduct(payload);
      context.commit(mutationTypes.activateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.activateFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.deactivateProduct](context, payload) {
    try {
      context.commit(mutationTypes.deactivateStart);

      const res = await productApi.deactivateProduct(payload);
      context.commit(mutationTypes.deactivateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.deactivateFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateProduct](context, payload) {
    try {
      context.commit(mutationTypes.updateProductStart);

      const res = await productApi.updateProduct(payload);
      context.commit(mutationTypes.updateProductSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateProductFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.archiveProduct](context, payload) {
    try {
      context.commit(mutationTypes.archiveStart);

      await productApi.archiveProduct(payload);
      context.commit(mutationTypes.archiveSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.archiveFailed, e.response.data.error);
      return false;
    }
  },
};
export const getterTypes = {
  totalItems: "[product] totalItems",
  totalPages: "[product] totalPages",
  titleErrors: "[product] productErrors",
  organizationErrors: "[product] organizationErrors",
  tillDateErrors: "[product] tillDateErrors",
  sinceDateErrors: "[product] sinceDateErrors",
  typeErrors: "[product] typeErrors",
  minIncomeErrors: "[product] minIncomeErrors",
  disposableErrors: "[product] disposableErrors",
  loanPercentErrors: "[product] loanPercentErrors",
  ageMinErrors: "[product] ageMinErrors",
  ageMaxErrors: "[product] ageMaxErrors",
  minLoanErrors: "[product] minLoanErrors",
  maxLoanErrors: "[product] maxLoanErrors",
  minAmountErrors: "[product] minAmountErrors",
  maxAmountErrors: "[product] maxAmountErrors",
  downPaymentErrors: "[product] downPaymentErrors",
  interestErrors: "[product] interestErrors",
  percentageErrors: "[product] percentageErrors",
  feeErrors: "[product] feeErrors",
  feeMaxErrors: "[product] feeMaxErrors",
  isArchived: "[product] isArchived",
};

const getters = {
  [getterTypes.isArchived](state) {
    return state.product.status === "archived";
  },
  [getterTypes.feeMaxErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "fee_maximum__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.feeErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "fee_percent__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.percentageErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "annual_percentage_rate__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.interestErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "annual_interest_rate__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.downPaymentErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "down_payment_percent_min__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.maxAmountErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "loan_amount_max__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    errors.find((i) => i == "loan_amount_min_max__mismatch") &&
      result.push(i18n.t("errors.loanAmountMismatch"));
    return result;
  },
  [getterTypes.minAmountErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "loan_amount_min__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.maxLoanErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "term_years_max__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    errors.find((i) => i == "term_years_min_max__mismatch") &&
      result.push(i18n.t("errors.monthsMismatch"));
    return result;
  },
  [getterTypes.minLoanErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "term_years_min__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.ageMaxErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "age_max__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    errors.find((i) => i == "age_min_max__mismatch") &&
      result.push(i18n.t("errors.ageMismatch"));
    return result;
  },
  [getterTypes.ageMinErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "age_min__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.loanPercentErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "loan_max_percent__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.disposableErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "disposable_income_percent__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },
  [getterTypes.tillDateErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "available_since_till__mismatch") &&
      result.push(i18n.t("errors.dateMismatch"));
    errors.find((i) => i == "available_till__invalid") &&
      result.push(i18n.t("errors.dateInvalid"));
    return result;
  },
  [getterTypes.sinceDateErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "available_since__invalid") &&
      result.push(i18n.t("errors.dateInvalid"));
    return result;
  },
  [getterTypes.typeErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "type__invalid") &&
      result.push(i18n.t("errors.productTypeInvalid"));
    return result;
  },
  [getterTypes.titleErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "title__required") &&
      result.push(i18n.t("errors.titleRequired"));
    errors.find((i) => i == "title__invalid") &&
      result.push(i18n.t("errors.titleInvalid"));
    return result;
  },
  [getterTypes.organizationErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "organization__required") &&
      result.push(i18n.t("errors.organizationRequired"));
    errors.find((i) => i == "organization__invalid") &&
      result.push(i18n.t("errors.organizationInvalid"));
    return result;
  },
  [getterTypes.minIncomeErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "min_income__invalid") &&
      result.push(i18n.t("errors.invalidValue"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
