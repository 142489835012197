import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/conversations/";

const getTickets = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getTicket = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const getMessages = ({ id, params }) => {
  return axios.get(`${urlPath}${id}/messages/?${params}`);
};

const handleTicket = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/action`, data);
};

const sendMessage = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/messages/text`, data);
};

const sendMedia = ({ id, formData, progressHandler }) => {
  return axios.post(`${urlPath}${id}/messages/attachment`, formData, {
    onUploadProgress: progressHandler,
  });
};

const assignSupport = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/assign`, data);
};

export default {
  getTickets,
  getTicket,
  getMessages,
  handleTicket,
  sendMessage,
  sendMedia,
  assignSupport,
};
