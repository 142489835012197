import axios from "@/plugins/axios";

const apiUrl = "/platform/api/v1/properties/";

const getProperties = (params) => {
  return axios.get(`${apiUrl}?${params}`);
};

const getProperty = (id) => {
  return axios.get(`${apiUrl}${id}`);
};

const changePropertyAgent = ({ id, data }) => {
  return axios.post(`${apiUrl}${id}/agent`, data);
};

export default {
  getProperties,
  getProperty,
  changePropertyAgent,
};
