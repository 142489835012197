import axios from "@/plugins/axios";

const apiUrl = "/platform/api/v1/apr/";

const getAprList = (params) => {
  return axios.get(`${apiUrl}?${params}`);
};

const createApr = (data) => {
  return axios.post(`${apiUrl}`, data);
};

const getAprItem = (id) => {
  return axios.get(`${apiUrl}${id}`);
};

const updateAprItem = (id, data) => {
  return axios.put(`${apiUrl}${id}`, data);
};

const removeAprItem = (id) => {
  return axios.delete(`${apiUrl}${id}`);
};

export default {
  getAprList,
  createApr,
  getAprItem,
  updateAprItem,
  removeAprItem,
};
