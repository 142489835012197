import profileApi from "@/api/profile";
import { i18n } from "@/plugins/i18n";

const state = {
  isSubmitting: false,
  errors: [],
  isLoading: false,
  isUpdatingAvatar: false,
  isResending: false,
  isUpdPassword: false,
  profile: {},
  isCodesLoading: false,
  codes: null,
  isQrLoading: false,
  qr: {},
  isDownloading: false,
  isEnabling: false,
  is2Fa: false,
};

export const mutationTypes = {
  resendEmailStart: "[auth] resendEmailStart",
  resendEmailFailed: "[auth] resendEmailFailed",
  resendEmailSuccess: "[auth] resendEmailSuccess",

  getProfileStart: "[auth] getProfileStart",
  getProfileFailed: "[auth] getProfileFailed",
  getProfileSuccess: "[auth] getProfileSuccess",

  updateProfileStart: "[auth] updateProfileStart",
  updateProfileFailed: "[auth] updateProfileFailed",
  updateProfileSuccess: "[auth] updateProfileSuccess",

  updateAvatarStart: "[auth] updateAvatarStart",
  updateAvatarFailed: "[auth] updateAvatarFailed",
  updateAvatarSuccess: "[auth] updateAvatarSuccess",

  updatePasswordStart: "[auth] updatePasswordStart",
  updatePasswordFailed: "[auth] updatePasswordFailed",
  updatePasswordSuccess: "[auth] updatePasswordSuccess",

  getCodesStart: "[auth] getCodesStart",
  getCodesFailed: "[auth] getCodesFailed",
  getCodesSuccess: "[auth] getCodesSuccess",

  downloadCodesStart: "[auth] getDownloadStart",
  downloadCodesFailed: "[auth] getDownloadFailed",
  downloadCodesSuccess: "[auth] getDownloadSuccess",

  getQrStart: "[auth] getQrStart",
  getQrFailed: "[auth] getQrFailed",
  getQrSuccess: "[auth] getQrSuccess",

  enableTwoFaStart: "[auth] enableTwoFaStart",
  enableTwoFaFailed: "[auth] enableTwoFaFailed",
  enableTwoFaSuccess: "[auth] enableTwoFaSuccess",

  disableTwoFaStart: "[auth] disableTwoFaStart",
  disableTwoFaFailed: "[auth] disableTwoFaFailed",
  disableTwoFaSuccess: "[auth] disableTwoFaSuccess",

  resetProfile: "[auth] resetProfile",
};

const mutations = {
  [mutationTypes.resetProfile](state) {
    state.profile = {};
  },

  [mutationTypes.resendEmailStart](state) {
    state.isResending = true;
    state.errors = [];
  },
  [mutationTypes.resendEmailSuccess](state, payload) {
    state.isResending = false;
    state.errors = [];
    state.profile = payload;
  },
  [mutationTypes.resendEmailFailed](state, payload) {
    state.isResending = false;
    state.errors = payload;
  },

  [mutationTypes.getProfileStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getProfileSuccess](state, payload) {
    state.isLoading = false;
    state.profile = payload;
    state.is2Fa = payload["2fa_enabled"];
    state.errors = [];
  },
  [mutationTypes.getProfileFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.updateProfileStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.updateProfileSuccess](state, payload) {
    state.isSubmitting = false;
    state.profile = payload;
  },
  [mutationTypes.updateProfileFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.updateAvatarStart](state) {
    state.isUpdatingAvatar = true;
    state.errors = [];
  },
  [mutationTypes.updateAvatarSuccess](state, payload) {
    state.isUpdatingAvatar = false;
    state.profile = payload;
  },
  [mutationTypes.updateAvatarFailed](state, payload) {
    state.isUpdatingAvatar = false;
    state.errors = payload;
  },

  [mutationTypes.updatePasswordStart](state) {
    state.isUpdPassword = true;
    state.errors = [];
  },
  [mutationTypes.updatePasswordSuccess](state) {
    state.isUpdPassword = false;
  },
  [mutationTypes.updatePasswordFailed](state, payload) {
    state.isUpdPassword = false;
    state.errors = payload;
  },

  [mutationTypes.getCodesStart](state) {
    state.isCodesLoading = true;
    state.errors = [];
  },
  [mutationTypes.getCodesSuccess](state, payload) {
    state.isCodesLoading = false;
    state.codes = payload;
    state.errors = [];
  },
  [mutationTypes.getCodesFailed](state, payload) {
    state.isCodesLoading = false;
    state.errors = payload;
  },

  [mutationTypes.downloadCodesStart](state) {
    state.isDownloading = true;
    state.errors = [];
  },
  [mutationTypes.downloadCodesSuccess](state) {
    state.isDownloading = false;
    state.errors = [];
  },
  [mutationTypes.downloadCodesFailed](state, payload) {
    state.isDownloading = false;
    state.errors = payload;
  },

  [mutationTypes.getQrStart](state) {
    state.isQrLoading = true;
    state.errors = [];
  },
  [mutationTypes.getQrSuccess](state, payload) {
    state.isQrLoading = false;
    state.qr = payload;
    state.errors = [];
  },
  [mutationTypes.getQrFailed](state, payload) {
    state.isQrLoading = false;
    state.errors = payload;
  },

  [mutationTypes.enableTwoFaStart](state) {
    state.isEnabling = true;
    state.errors = [];
  },
  [mutationTypes.enableTwoFaSuccess](state) {
    state.isEnabling = false;
    state.errors = [];
    state.is2Fa = true;
  },
  [mutationTypes.enableTwoFaFailed](state, payload) {
    state.isEnabling = false;
    state.errors = payload;
  },

  [mutationTypes.disableTwoFaStart](state) {
    state.isEnabling = true;
    state.errors = [];
  },
  [mutationTypes.disableTwoFaSuccess](state) {
    state.isEnabling = false;
    state.errors = [];
    state.is2Fa = false;
  },
  [mutationTypes.disableTwoFaFailed](state, payload) {
    state.isEnabling = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  resendEmail: "[profile] resendEmail",
  getProfile: "[profile] getProfile",
  updateProfile: "[profile] updateProfile",
  updateAvatar: "[profile] updateAvatar",
  updatePassword: "[profile] updatePassword",
  getCodes: "[profile] getCodes",
  getQr: "[profile] getQr",
  downloadCodes: "[profile] downloadCodes",
  enableTwoFa: "[profile] enableTwoFa",
  disableTwoFa: "[profile] disableTwoFa",
  generateCodes: "[profile] generateCodes",
};

const actions = {
  async [actionTypes.resendEmail](context) {
    try {
      context.commit(mutationTypes.resendEmailStart);

      const res = await profileApi.resendEmail();
      context.commit(mutationTypes.resendEmailSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.resendEmailFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getProfile](context) {
    try {
      context.commit(mutationTypes.getProfileStart);

      const res = await profileApi.getProfile();
      context.commit(mutationTypes.getProfileSuccess, res.data);

      return true;
    } catch (e) {
      context.commit(mutationTypes.getProfileFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.updateProfile](context, payload) {
    try {
      context.commit(mutationTypes.updateProfileStart);

      const res = await profileApi.updateProfile(payload);
      context.commit(mutationTypes.updateProfileSuccess, res.data);

      return true;
    } catch (e) {
      context.commit(mutationTypes.updateProfileFailed, e.response.data.error);

      return false;
    }
  },

  async [actionTypes.updateAvatar](context, payload) {
    try {
      context.commit(mutationTypes.updateAvatarStart);
      const res = await profileApi.updateAvatar(payload);
      context.commit(mutationTypes.updateAvatarSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateAvatarFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updatePassword](context, payload) {
    try {
      context.commit(mutationTypes.updatePasswordStart);
      await profileApi.updatePassword(payload);
      context.commit(mutationTypes.updatePasswordSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updatePasswordFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getCodes](context) {
    try {
      context.commit(mutationTypes.getCodesStart);
      const res = await profileApi.getCodes();
      context.commit(mutationTypes.getCodesSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getCodesFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.generateCodes](context) {
    try {
      context.commit(mutationTypes.getCodesStart);
      const res = await profileApi.generateCodes();
      context.commit(mutationTypes.getCodesSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getCodesFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getQr](context) {
    try {
      context.commit(mutationTypes.getQrStart);
      const res = await profileApi.getQr();
      context.commit(mutationTypes.getQrSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getQrFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.downloadCodes](context) {
    try {
      context.commit(mutationTypes.downloadCodesStart);
      const res = await profileApi.downloadCodes();
      const type = res.headers["content-type"];
      const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "codes.txt";
      link.click();
      context.commit(mutationTypes.downloadCodesSuccess);
    } catch (e) {
      context.commit(mutationTypes.getQrFailed, e.response.data.error);
    }
  },

  async [actionTypes.enableTwoFa](context, payload) {
    try {
      context.commit(mutationTypes.enableTwoFaStart);
      await profileApi.enableTwoFa(payload);
      context.commit(mutationTypes.enableTwoFaSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.enableTwoFaFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.disableTwoFa](context) {
    try {
      context.commit(mutationTypes.disableTwoFaStart);
      await profileApi.disableTwoFa();
      context.commit(mutationTypes.disableTwoFaSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.disableTwoFaFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  firstNameErrors: "[profile] firstNameErrors",
  lastNameErrors: "[profile] lastNameErrors",
  emailErrors: "[profile] emailErrors",
  isEmailConfirmed: "[profile] isEmailConfirmed",
  passwordErrors: "[profile] passwordErrors",
  newPasswordErrors: "[profile] newPasswordErrors",
  codesList: "[profile] codesList",
  codeErrors: "[profile] codeErrors",
  newMessagesCount: "[profile] newMessagesCount",
};

const getters = {
  [getterTypes.newMessagesCount](state) {
    return state.profile.new_notifications_count || 0;
  },
  [getterTypes.codesList](state) {
    return state.codes?.codes || [];
  },
  [getterTypes.isEmailConfirmed](state) {
    const confirmation = state.profile.is_email_confirmed;
    return confirmation === undefined ? true : confirmation;
  },
  [getterTypes.firstNameErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "first_name__required") &&
      result.push(i18n.t("errors.firstNameRequired"));
    errors.find((i) => i == "first_name__invalid") &&
      result.push(i18n.t("errors.firstNameInvalid"));
    return result;
  },
  [getterTypes.lastNameErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "last_name__required") &&
      result.push(i18n.t("errors.lastNameRequired"));
    errors.find((i) => i == "last_name__invalid") &&
      result.push(i18n.t("errors.lastNameInvalid"));
    return result;
  },
  [getterTypes.emailErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i == "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i == "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    errors.find((i) => i == "email__update_too_often") &&
      result.push(i18n.t("errors.emailUpdTooOften"));
    return result;
  },
  [getterTypes.passwordErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "current_password__required") &&
      result.push(i18n.t("errors.currentPasswordRequired"));
    errors.find((i) => i == "current_password__invalid") &&
      result.push(i18n.t("errors.currentPasswordInvalid"));
    return result;
  },
  [getterTypes.newPasswordErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "new_password__required") &&
      result.push(i18n.t("errors.newPasswordRequired"));
    errors.find((i) => i == "new_password__invalid") &&
      result.push(i18n.t("errors.newPasswordWeak"));
    return result;
  },

  [getterTypes.codeErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "code__required") &&
      result.push(i18n.t("errors.codeRequired"));
    errors.find((i) => i == "otp__invalid") &&
      result.push(i18n.t("errors.otpInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
