import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/products/";

const getProducts = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getProduct = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const createProduct = (data) => {
  return axios.post(`${urlPath}`, data);
};

const activateProduct = (id) => {
  return axios.post(`${urlPath}${id}/activate`);
};

const deactivateProduct = (id) => {
  return axios.post(`${urlPath}${id}/deactivate`);
};

const updateProduct = ({ id, data }) => {
  return axios.patch(`${urlPath}${id}`, data);
};

const archiveProduct = (id) => {
  return axios.delete(`${urlPath}${id}`);
};

export default {
  getProducts,
  getProduct,
  createProduct,
  activateProduct,
  deactivateProduct,
  updateProduct,
  archiveProduct,
};
