import propertyApi from "@/api/property";
import { itemsPerPage } from "@/helpers/paginations";
import { i18n } from "@/plugins/i18n";

const state = {
  data: {},
  errors: [],
  isLoading: false,
  searchValue: "",
  page: 1,
  itemsPerPage: itemsPerPage,
  property: null,
  isUpdating: false,
};

export const mutationTypes = {
  getPropertiesStart: "[property] getPropertiesStart",
  getPropertiesFailed: "[property] getPropertiesFailed",
  getPropertiesSuccess: "[property] getPropertiesSuccess",

  getPropertyStart: "[property] getPropertyStart",
  getPropertyFailed: "[property] getPropertyFailed",
  getPropertySuccess: "[property] getPropertySuccess",

  updateStart: "[property] updateStart",
  updateFailed: "[property] updateFailed",
  updateSuccess: "[property] updateSuccess",

  changeSearchValue: "[property] changeSearchValue",
  changePagination: "[property] changePagination",
};

const mutations = {
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },

  [mutationTypes.getPropertiesStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getPropertiesSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getPropertiesFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getPropertyStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getPropertySuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.property = payload;
  },
  [mutationTypes.getPropertyFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.updateStart](state) {
    state.isUpdating = true;
    state.errors = [];
  },
  [mutationTypes.updateSuccess](state, payload) {
    state.isUpdating = false;
    state.errors = [];
    state.property = payload;
  },
  [mutationTypes.updateFailed](state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getProperties: "[property] getProperties",
  getProperty: "[property] getProperty",
  updateProperty: "[property] updateProperty",
};

const actions = {
  async [actionTypes.getProperties](context, payload) {
    try {
      context.commit(mutationTypes.getPropertiesStart);

      const res = await propertyApi.getProperties(payload);
      context.commit(mutationTypes.getPropertiesSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getPropertiesFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getProperty](context, payload) {
    try {
      context.commit(mutationTypes.getPropertyStart);

      const res = await propertyApi.getProperty(payload);
      context.commit(mutationTypes.getPropertySuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getPropertyFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateProperty](context, payload) {
    try {
      context.commit(mutationTypes.updateStart);

      const res = await propertyApi.changePropertyAgent(payload);
      context.commit(mutationTypes.updateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  totalItems: "[property] totalItems",
  totalPages: "[property] totalPages",
  propertyAgentErrors: "[property] propertyAgentErrors",
};
const getters = {
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },
  [getterTypes.propertyAgentErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "agent__required") &&
      result.push(i18n.t("errors.agentRequired"));
    errors.find((i) => i == "agent__invalid") &&
      result.push(i18n.t("errors.agentInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
