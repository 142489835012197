import requestApi from "@/api/request";
import { itemsPerPage } from "@/helpers/paginations";
import translateErrors from "@/store/translateErrors";

const state = {
  errors: [],
  isLoading: false,
  data: {},
  request: {},
  isChanging: false,
  searchValue: "",
  page: 1,
  status: "all",
  itemsPerPage: itemsPerPage,
  history: [],
  offers: [],
  isLoadingOffers: false,
};

export const mutationTypes = {
  getRequestsStart: "[request] getRequestsStart",
  getRequestsFailed: "[request] getRequestsFailed",
  getRequestsSuccess: "[request] getRequestsSuccess",

  getRequestStart: "[request] getRequestStart",
  getRequestFailed: "[request] getRequestFailed",
  getRequestSuccess: "[request] getRequestSuccess",

  changeStatusStart: "[request] changeStatusStart",
  changeStatusFailed: "[request] changeStatusFailed",
  changeStatusSuccess: "[request] changeStatusSuccess",

  resetErrors: "[request] resetErrors",
  changeSearchValue: "[request] changeSearchValue",
  changeStatusVal: "[request] changeStatusVal",
  changePagination: "[request] changePagination",

  setHistory: "[request] setHistory",

  getOffersStart: "[request] getRequestOffersStart",
  getOffersFailed: "[request] getRequestOffersFailed",
  getOffersSuccess: "[request] getRequestOffersSuccess",
};

const mutations = {
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },
  [mutationTypes.changeStatusVal](state, payload) {
    state.status = payload;
  },
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },
  [mutationTypes.getRequestsStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getRequestsSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getRequestsFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getRequestStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getRequestSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.request = payload;
  },
  [mutationTypes.getRequestFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.changeStatusStart](state) {
    state.isChanging = true;
    state.errors = [];
  },
  [mutationTypes.changeStatusSuccess](state, payload) {
    state.isChanging = false;
    state.errors = [];
    state.request = payload;
  },
  [mutationTypes.changeStatusFailed](state, payload) {
    state.isChanging = false;
    state.errors = payload;
  },
  [mutationTypes.setHistory](state, payload) {
    state.history = payload;
  },

  [mutationTypes.getOffersStart](state) {
    state.isLoadingOffers = true;
    state.errors = [];
  },
  [mutationTypes.getOffersSuccess](state, payload) {
    state.isLoadingOffers = false;
    state.errors = [];
    state.offers = payload;
    return payload
  },
  [mutationTypes.getOffersFailed](state, payload) {
    state.isLoadingOffers = false;
    state.errors = payload;
  }

};

export const actionTypes = {
  getRequests: "[request] getRequests",
  getRequest: "[request] getRequest",
  changeStatus: "[request] changeStatus",
  getHistory: "[request] getHistory",
  createRequest: "[request] createRequest",
  updateRequest: "[request] updateRequest",
  getOffers: "[request] getOffers",
  submitRequest: "[request] submitRequest",
  cancelRequest: "[request] cancelRequest",
};

const actions = {
  async [actionTypes.getRequests](context, payload) {
    try {
      context.commit(mutationTypes.getRequestsStart);

      const res = await requestApi.getRequests(payload);
      context.commit(mutationTypes.getRequestsSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getRequestsFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getRequest](context, payload) {
    try {
      context.commit(mutationTypes.getRequestStart);

      const res = await requestApi.getRequest(payload);
      context.commit(mutationTypes.getRequestSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getRequestFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.changeStatus](context, payload) {
    try {
      context.commit(mutationTypes.changeStatusStart);

      const res = await requestApi.changeStatus(payload);
      context.commit(mutationTypes.changeStatusSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.changeStatusFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getHistory](context, payload) {
    try {
      const res = await requestApi.getHistory(payload);
      context.commit(mutationTypes.setHistory, res.data)
    } catch (e) {
      return false
    }
  },

  async [actionTypes.createRequest](context, payload) {
    try {
      context.commit(mutationTypes.getRequestStart);
      const res = await requestApi.createRequest(payload);
      context.commit(mutationTypes.getRequestSuccess, res.data);
      return res.data
    } catch (e) {
      context.commit(mutationTypes.getRequestFailed, e.response.data.error);
      return false
    }
  },


  async [actionTypes.updateRequest](context, payload) {
    try {
      context.commit(mutationTypes.getRequestStart);
      const res = await requestApi.updateRequest(payload);
      context.commit(mutationTypes.getRequestSuccess, res.data);
      return res.data
    } catch (e) {
      context.commit(mutationTypes.getRequestFailed, e.response.data.error);
      return false
    }
  },

  async [actionTypes.getOffers](context, payload) {
    try {
      context.commit(mutationTypes.getOffersStart);
      const res = await requestApi.getOffers(payload);
      context.commit(mutationTypes.getOffersSuccess, res.data);
      return res.data
    } catch (e) {
      context.commit(mutationTypes.getOffersFailed, e.response.data.error);
      return false
    }
  },

  async [actionTypes.submitRequest](context, payload) {
    try {
      const res = await requestApi.submitRequest(payload);
      return res.data
    } catch (e) {
      return false
    }
  },

  async [actionTypes.cancelRequest](context, payload) {
    try {
      await requestApi.cancelRequest(payload);
      return true
    } catch (e) {
      return false
    }
  },

};
export const getterTypes = {
  offer: "[request] offer",
  customer: "[request] customer",
  customerProperty: "[request] customerProperty",
  agentProperty: "[request] agentProperty",
  requestStatus: "[request] requestStatus",
  totalItems: "[request] totalItems",
  totalPages: "[request] totalPages",
  history: "[request] history",
  financingProduct: "[request] financingProduct",
  financingProperty: "[request] financingProperty",
  financingUnit: "[request] financingUnit",
  isLoading: "[request] isLoading",
  errors: "[request] errors",
  offers: "[request] offers",
  isLoadingOffers: "[request] isLoadingOffers",
};

const getters = {
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },
  [getterTypes.requestStatus](state) {
    return state.request.status;
  },
  [getterTypes.agentProperty](state) {
    return state.request.agent_property || {};
  },
  [getterTypes.customerProperty](state) {
    return state.request.customer_property || {};
  },
  [getterTypes.offer](state) {
    return state.request.offer || {};
  },
  [getterTypes.financingProperty](state) {
    return state.request.real_estate_reservation?.property || {};
  },
  [getterTypes.customer](state) {
    return state.request.customer || {};
  },
  [getterTypes.financingProduct](state) {
    return state.request.product || {};
  },
  [getterTypes.financingUnit](state) {
    return state.request.real_estate_reservation?.unit || {};
  },
  [getterTypes.history](state) {
    let defaultHistory = [
      'submitted', 'processing', 'pre_approved', 'approved',
      'pre_rejected', 'rejected', 'canceled'
    ].map(el => ({status: el}))

    state.history.forEach(el => {
      defaultHistory = defaultHistory.map(item => {
        if (item.status === el.status) return el
        return item
      })
    })

    return defaultHistory;
  },

  [getterTypes.isLoading](state) {
    return state.isLoading;
  },
  [getterTypes.errors](state) {
    return state.errors;
  },
  [getterTypes.offers](state) {
    return state.offers;
  },
  [getterTypes.isLoadingOffers](state) {
    return state.isLoadingOffers;
  },

  customer_first_name_errors: translateErrors('customer_first_name'),
  customer_father_name_errors: translateErrors('customer_father_name'),
  customer_grandfather_name_errors: translateErrors('customer_grandfather_name'),
  customer_family_name_errors: translateErrors('customer_family_name'),
  customer_id_number_errors: translateErrors('customer_id_number'),
  customer_birthday_errors: translateErrors('customer_birthday'),
  customer_gender_errors: translateErrors('customer_gender'),
  customer_email_errors: translateErrors('customer_email'),
  customer_phone_errors: translateErrors('customer_phone_code', ['customer_phone_number__required', 'customer_phone_number__invalid']),
  customer_property_type_errors: translateErrors('customer_property_type'),
  customer_property_building_status_errors: translateErrors('customer_property_building_status'),
  customer_property_city_errors: translateErrors('customer_property_city', ['city__required, city__invalid']),
  customer_property_address_errors: translateErrors('customer_property_address'),
  customer_property_estimated_price_errors: translateErrors('customer_property_estimated_price'),
  employment_type_errors: translateErrors('employment_type'),
  employment_subtype_errors: translateErrors('employment_subtype'),
  fees_help_requested_errors: translateErrors('fees_help_requested'),
  loan_preferred_monthly_installment_errors: translateErrors('loan_preferred_monthly_installment'),
  loan_down_payment_errors: translateErrors('loan_down_payment'),
  loan_term_years_errors: translateErrors('loan_term_years'),
  loan_amount_percent_errors: translateErrors('loan_amount_percent'),
  has_mortgage_errors: translateErrors('has_mortgage'),
  family_members_count_errors: translateErrors('family_members_count'),
  moh_supported_errors: translateErrors('moh_supported'),
  active_loan_remaining_months_errors: translateErrors('active_loan_remaining_months'),
  active_loan_amount_per_month_errors: translateErrors('active_loan_amount_per_month'),
  official_monthly_obligations_errors: translateErrors('official_monthly_obligations'),
  official_monthly_income_errors: translateErrors('official_monthly_income'),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
