<template>
  <div>
    <audio autoplay :src="require('@/assets/alert.mp3')"></audio>
    <div class="white--text f-16" style="max-width: 335px">
      {{ message }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
