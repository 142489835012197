import { itemsPerPage } from "@/helpers/paginations";
import { i18n } from "@/plugins/i18n";
import axios from "@/plugins/axios";

const state = {
  data: {},
  errors: [],
  isLoading: false,
  searchValue: "",
  page: 1,
  itemsPerPage: itemsPerPage,
  request: {},
  isUpdating: false,
};

export const mutationTypes = {
  getRealEstateRequestsStart: "[realEstate] getRealEstateRequestsStart",
  getRealEstateRequestsFailed: "[realEstate] getRealEstateRequestsFailed",
  getRealEstateRequestsSuccess: "[realEstate] getRealEstateRequestsSuccess",

  getRealEstateRequestStart: "[realEstate] getRealEstateRequestStart",
  getRealEstateRequestFailed: "[realEstate] getRealEstateRequestFailed",
  getRealEstateRequestSuccess: "[realEstate] getRealEstateRequestSuccess",

  updateStart: "[realEstate] updateStart",
  updateFailed: "[realEstate] updateFailed",
  updateSuccess: "[realEstate] updateSuccess",

  changeSearchValue: "[realEstate] changeSearchValue",
  changePagination: "[realEstate] changePagination",
};

const mutations = {
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },

  [mutationTypes.getRealEstateRequestsStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getRealEstateRequestsSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getRealEstateRequestsFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getRealEstateRequestStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getRealEstateRequestSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.request = payload;
  },
  [mutationTypes.getRealEstateRequestFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.updateStart](state) {
    state.isUpdating = true;
    state.errors = [];
  },
  [mutationTypes.updateSuccess](state, payload) {
    state.isUpdating = false;
    state.errors = [];
    state.request = payload;
  },
  [mutationTypes.updateFailed](state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getRealEstateRequests: "[realEstate] getRealEstateRequests",
  getRealEstateRequest: "[realEstate] getRealEstateRequest",
  updateStatus: "[realEstate] updateStatus",
};

const actions = {
  async [actionTypes.getRealEstateRequests](context, payload) {
    try {
      context.commit(mutationTypes.getRealEstateRequestsStart);

      const res = await axios.get(`/platform/api/v1/real-estate-requests/?${payload}`);
      context.commit(mutationTypes.getRealEstateRequestsSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getRealEstateRequestsFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getRealEstateRequest](context, id) {
    try {
      context.commit(mutationTypes.getRealEstateRequestStart);

      const res = await axios.get(`/platform/api/v1/real-estate-requests/${id}`)
      context.commit(mutationTypes.getRealEstateRequestSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getRealEstateRequestFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateStatus](context, {id, status}) {
    try {
      context.commit(mutationTypes.updateStart);

      const res = await axios.post(`/platform/api/v1/real-estate-requests/${id}/status`, {status});
      context.commit(mutationTypes.updateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  totalItems: "[realEstate] totalItems",
  totalPages: "[realEstate] totalPages",
  itemsPerPage: "[realEstate] itemsPerPage",
  requestAgentErrors: "[request] requestAgentErrors",
};
const getters = {
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },
  [getterTypes.requestAgentErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "agent__required") &&
    result.push(i18n.t("errors.agentRequired"));
    errors.find((i) => i == "agent__invalid") &&
    result.push(i18n.t("errors.agentInvalid"));
    return result;
  },
  [getterTypes.itemsPerPage]: (state) => state.itemsPerPage,
};

export default {
  state,
  mutations,
  actions,
  getters,
};