import commonApi from "@/api/common";
import {i18n} from "@/plugins/i18n";

const state = {
  employmentTypes: [],
  propertyBuildingStatus: ['ready_unit', 'sale_on_map', 'self_built', 'other'],
  propertyTypes: [],
  propertyTypesLocal: [
    { value: 'flat', name: i18n.t('propertyTypes.flat') },
    { value: 'house', name: i18n.t('propertyTypes.house')},
    { value: 'studio', name: i18n.t('propertyTypes.studio')},
    { value: 'hotel_apartment', name: i18n.t('propertyTypes.hotel_apartment')},
    { value: 'apartment', name: i18n.t('propertyTypes.apartment')},
    { value: 'villa', name: i18n.t('propertyTypes.villa')},
    { value: 'room', name: i18n.t('propertyTypes.room')},
    { value: 'other', name: i18n.t('propertyTypes.other')},
  ],
  cities: [],
};

export const mutationTypes = {
  getEmploymentTypes: "[common] getEmploymentTypes",
};

export const actionTypes = {
  getEmploymentTypes: "[common] getEmploymentTypes",
  getPropertyTypes: "[common] getPropertyTypes",
  getCities: "[common] getCities",
};

const mutations = {
  [mutationTypes.getEmploymentTypes](state, payload) {
    state.employmentTypes = payload;
  },

  setPropertyTypes(state, payload) {
    state.propertyTypes = payload
  },

  setCities(state, payload) {
    state.cities = payload.result
  }

};

const actions = {
  async [actionTypes.getEmploymentTypes](context) {
    try {
      const response = await commonApi.getEmploymentTypes();
      context.commit(mutationTypes.getEmploymentTypes, response.data);
    } catch (e) {
      console.log(e.response.data.error);
    }
  },

  async [actionTypes.getPropertyTypes](context) {
    try {
      const response = await commonApi.getPropertyTypes();
      context.commit('setPropertyTypes', response.data)
      return response.data
    } catch (e) {
      console.log(e.response.data.error);
    }
  },

  async [actionTypes.getCities](context) {
    try {
      const response = await commonApi.getCities();
      context.commit('setCities', response.data)
      return response.data
    } catch (e) {
      console.log(e.response.data.error);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
