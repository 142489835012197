import axios from "@/plugins/axios";

const url = "/platform/api/v1/fab-rates/";

const getFabList = (params) => {
  return axios.get(`${url}?${params}`);
};

const addFab = (data) => {
  return axios.post(url, data);
};

const getFabItem = (id) => {
  return axios.get(`${url}${id}`);
};

const updateFabItem = (id, data) => {
  return axios.put(`${url}${id}`, data);
};

const removeFabItem = (id) => {
  return axios.delete(`${url}${id}`);
};

export default {
  getFabList,
  addFab,
  getFabItem,
  updateFabItem,
  removeFabItem,
};
