import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/requests/";

const getRequests = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getRequest = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const changeStatus = ({ id, action, bank_agent }) => {
  return axios.post(`${urlPath}${id}/action`, { action, bank_agent });
};

const getHistory = (id) => {
  return axios.get(`${urlPath}${id}/history/`);
}

const createRequest = (data) => {
  return axios.post(`${urlPath}`, data);
};

const updateRequest = ({number, data}) => {
  return axios.put(`${urlPath}${number}`, data);
};

const getOffers = (number) => {
  return axios.get(`${urlPath}${number}/offers/`);
};

const submitRequest = ({number, data}) => {
  return axios.post(`${urlPath}${number}/submit`, data);
};

const cancelRequest = (number) => {
  return axios.delete(`${urlPath}${number}`);
};

export default {
  getRequests,
  getRequest,
  changeStatus,
  getHistory,
  createRequest,
  updateRequest,
  getOffers,
  submitRequest,
  cancelRequest
};
