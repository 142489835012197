import api from "@/api/apr";
import { i18n } from "@/plugins/i18n";
import { itemsPerPage } from "@/helpers/paginations";

const state = {
  isLoading: false,
  aprList: [],
  aprItem: {},
  searchValue: "",
  page: 1,
  itemsPerPage: itemsPerPage,
  errors: [],
};

export const mutationTypes = {
  getAprListStart: "[apr] getAprListStart",
  getAprListFailed: "[apr] getAprListFailed",
  getAprListSuccess: "[apr] getAprListSuccess",

  createAprStart: "[apr] createAprStart",
  createAprFailed: "[apr] createAprFailed",
  createAprSuccess: "[apr] createAprSuccess",

  getAprItemStart: "[apr] getAprItemStart",
  getAprItemFailed: "[apr] getAprItemFailed",
  getAprItemSuccess: "[apr] getAprItemSuccess",

  updateAprStart: "[apr] updateAprStart",
  updateAprFailed: "[apr] updateAprFailed",
  updateAprSuccess: "[apr] updateAprSuccess",

  removeAprProcess: "[apr] removeAprProcess",
  removeAprFailed: "[apr] removeAprFailed",

  changeSearchValue: "[apr] changeSearchValue",
  changePagination: "[apr] changePagination",
  resetErrors: "[apr] resetErrors",
};

const mutations = {
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },

  [mutationTypes.getAprListStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getAprListSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.aprList = payload;
  },
  [mutationTypes.getAprListFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.createAprStart](state) {
    state.errors = [];
  },
  [mutationTypes.createAprSuccess](state, payload) {
    state.errors = [];
    state.aprItem = payload;
  },
  [mutationTypes.createAprFailed](state, payload) {
    state.errors = payload;
  },

  [mutationTypes.getAprItemStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getAprItemSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.aprItem = payload;
  },
  [mutationTypes.getAprItemFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.updateAprStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.updateAprSuccess](state, payload) {
    state.aprItem = payload;
    state.errors = [];
  },
  [mutationTypes.updateAprFailed](state, payload) {
    state.errors = payload;
  },

  [mutationTypes.removeAprProcess](state) {
    state.errors = [];
  },
  [mutationTypes.removeAprFailed](state, payload) {
    state.errors = payload;
  },
};

export const actionTypes = {
  getAprList: "[apr] getAprList",
  getAprItem: "[apr] getAprItem",
  createApr: "[apr] createApr",
  removeApr: "[apr] removeApr",
  updateApr: "[apr] updateApr",
};

const actions = {
  async [actionTypes.getAprList](context, payload) {
    try {
      context.commit(mutationTypes.getAprListStart);
      const res = await api.getAprList(payload);
      context.commit(mutationTypes.getAprListSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAprListFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.createApr](context, payload) {
    try {
      context.commit(mutationTypes.createAprStart);
      const res = await api.createApr(payload);
      context.commit(mutationTypes.createAprSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.createAprFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getAprItem](context, payload) {
    try {
      context.commit(mutationTypes.getAprItemStart);
      const res = await api.getAprItem(payload);
      context.commit(mutationTypes.getAprItemSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAprItemFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateApr](context, { id, data }) {
    try {
      context.commit(mutationTypes.updateAprStart);

      const res = await api.updateAprItem(id, data);
      context.commit(mutationTypes.updateAprSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateAprFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.removeApr](context, payload) {
    try {
      context.commit(mutationTypes.removeAprProcess);

      await api.removeAprItem(payload);
      return true;
    } catch (e) {
      context.commit(mutationTypes.removeAprFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  totalItems: "[apr] totalItems",
  totalPages: "[apr] totalPages",
  organizationErrors: "[apr] organizationErrors",
  ageLessThenErrors: "[apr] ageLessThenErrors",
  employeeTypeErrors: "[apr] employeeTypeErrors",
  tenureMaxErrors: "[apr] tenureMaxErrors",
  aprPercentErrors: "[apr] aprPercentErrors",
  maxAmountErrors: "[apr] maxAmountErrors",
  minAmountErrors: "[apr] minAmountErrors",
  incomeMinErrors: "[apr] incomeMinErrors",
  loanDurationMonthsMinErrors: "[apr] loanDurationMonthsMinErrors",
  loanDurationMonthsMaxErrors: "[apr] loanDurationMonthsMaxErrors",
  ageMinErrors: "[apr] ageMinErrors",
  ageMaxErrors: "[apr] ageMaxErrors",
  disposableErrors: "[apr] disposableErrors",
  downPaymentErrors: "[apr] downPaymentErrors",
  managementFeePercentErrors: "[apr] managementFeePercentErrors",
  managementFeeMaxErrors: "[apr] managementFeeMaxErrors",
};

const getters = {
  [getterTypes.totalPages](state) {
    return state.aprList.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.aprList.total || 0;
  },
  [getterTypes.organizationErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "organization__required") &&
      result.push(i18n.t("errors.organizationRequired"));
    errors.find((i) => i == "organization__invalid") &&
      result.push(i18n.t("errors.organizationInvalid"));
    return result;
  },
  [getterTypes.ageLessThenErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "age_less_then__required") &&
      result.push(i18n.t("errors.ageLessThenRequired"));
    errors.find((i) => i == "age_less_then__invalid") &&
      result.push(i18n.t("errors.ageLessThenInvalid"));
    return result;
  },
  [getterTypes.employeeTypeErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "employee_type__required") &&
      result.push(i18n.t("errors.employeeTypeRequired"));
    errors.find((i) => i == "employee_type__invalid") &&
      result.push(i18n.t("errors.employeeTypeInvalid"));
    return result;
  },
  [getterTypes.tenureMaxErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "tenure_max__required") &&
      result.push(i18n.t("errors.tenureMaxRequired"));
    errors.find((i) => i == "tenure_max__invalid") &&
      result.push(i18n.t("errors.tenureMaxInvalid"));
    return result;
  },
  [getterTypes.aprPercentErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "apr_percent__required") &&
      result.push(i18n.t("errors.aprPercentRequired"));
    errors.find((i) => i == "apr_percent__invalid") &&
      result.push(i18n.t("errors.aprPercentInvalid"));
    return result;
  },
  [getterTypes.maxAmountErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "loan_amount_max__required") &&
      result.push(i18n.t("errors.loanAmountMaxRequired"));
    errors.find((i) => i == "loan_amount_max__invalid") &&
      result.push(i18n.t("errors.loanAmountMaxInvalid"));
    return result;
  },
  [getterTypes.minAmountErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "loan_amount_min__required") &&
      result.push(i18n.t("errors.loanAmountMinRequired"));
    errors.find((i) => i == "loan_amount_min__invalid") &&
      result.push(i18n.t("errors.loanAmountMinInvalid"));
    return result;
  },
  [getterTypes.incomeMinErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "income_min__required") &&
      result.push(i18n.t("errors.incomeMinRequired"));
    errors.find((i) => i == "income_min__invalid") &&
      result.push(i18n.t("errors.incomeMinInvalid"));
    return result;
  },
  [getterTypes.loanDurationMonthsMinErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "loan_duration_months_min__required") &&
      result.push(i18n.t("errors.loanDurationMonthsMinRequired"));
    errors.find((i) => i == "loan_duration_months_min__invalid") &&
      result.push(i18n.t("errors.loanDurationMonthsMinInvalid"));
    return result;
  },
  [getterTypes.loanDurationMonthsMaxErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "loan_duration_months_max__required") &&
      result.push(i18n.t("errors.loanDurationMonthsMaxRequired"));
    errors.find((i) => i == "loan_duration_months_max__invalid") &&
      result.push(i18n.t("errors.loanDurationMonthsMaxInvalid"));
    return result;
  },
  [getterTypes.ageMinErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "age_min__required") &&
      result.push(i18n.t("errors.ageMinRequired"));
    errors.find((i) => i == "age_min__invalid") &&
      result.push(i18n.t("errors.ageMinInvalid"));
    return result;
  },
  [getterTypes.ageMaxErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "age_max__required") &&
      result.push(i18n.t("errors.ageMaxRequired"));
    errors.find((i) => i == "age_max__invalid") &&
      result.push(i18n.t("errors.ageMaxInvalid"));
    return result;
  },
  [getterTypes.disposableErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "disposable_income_percent__required") &&
      result.push(i18n.t("errors.disposableIncomePercentRequired"));
    errors.find((i) => i == "disposable_income_percent__invalid") &&
      result.push(i18n.t("errors.disposableIncomePercentInvalid"));
    return result;
  },
  [getterTypes.downPaymentErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "down_payment_percent_min__required") &&
      result.push(i18n.t("errors.downPaymentPercentMinRequired"));
    errors.find((i) => i == "down_payment_percent_min__invalid") &&
      result.push(i18n.t("errors.downPaymentPercentMinInvalid"));
    return result;
  },
  [getterTypes.managementFeePercentErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "management_fee_percent__required") &&
      result.push(i18n.t("errors.managementFeePercentRequired"));
    errors.find((i) => i == "management_fee_percent__invalid") &&
      result.push(i18n.t("errors.managementFeePercentInvalid"));
    return result;
  },
  [getterTypes.managementFeeMaxErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "management_fee_maximum__required") &&
      result.push(i18n.t("errors.managementFeeMaxRequired"));
    errors.find((i) => i == "management_fee_maximum__invalid") &&
      result.push(i18n.t("errors.managementFeeMaxInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
