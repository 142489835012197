import customerApi from "@/api/customer";
import { itemsPerPage } from "@/helpers/paginations";

const state = {
  data: {},
  errors: [],
  isLoading: false,
  searchValue: "",
  page: 1,
  itemsPerPage: itemsPerPage,
  customer: {},
};

export const mutationTypes = {
  getCustomersStart: "[customer] getCustomersStart",
  getCustomersFailed: "[customer] getCustomersFailed",
  getCustomersSuccess: "[customer] getCustomersSuccess",

  getCustomerStart: "[customer] getCustomerStart",
  getCustomerFailed: "[customer] getCustomerFailed",
  getCustomerSuccess: "[customer] getCustomerSuccess",

  changeSearchValue: "[customer] changeSearchValue",
  changePagination: "[customer] changePagination",
};

const mutations = {
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },

  [mutationTypes.getCustomersStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getCustomersSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getCustomersFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getCustomerStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getCustomerSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.customer = payload;
  },
  [mutationTypes.getCustomerFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getCustomers: "[customer] getCustomers",
  getCustomer: "[customer] getCustomer",
};

const actions = {
  async [actionTypes.getCustomers](context, payload) {
    try {
      context.commit(mutationTypes.getCustomersStart);

      const res = await customerApi.getCustomers(payload);
      context.commit(mutationTypes.getCustomersSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getCustomersFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getCustomer](context, payload) {
    try {
      context.commit(mutationTypes.getCustomerStart);

      const res = await customerApi.getCustomer(payload);
      context.commit(mutationTypes.getCustomerSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getCustomerFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  totalItems: "[customer] totalItems",
  totalPages: "[customer] totalPages",
};
const getters = {
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
