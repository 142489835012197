import api from "@/api/projects";
import { itemsPerPage } from "@/helpers/paginations";

const state = {
  data: {},
  errors: [],
  isLoading: false,
  searchValue: "",
  page: 1,
  statusValue: "",
  itemsPerPage: itemsPerPage,
  item: null,
  itemUnits: [],
};

export const mutationTypes = {
  getProjectsListStart: "[projects] getProjectsListStart",
  getProjectsListFailed: "[projects] getProjectsListFailed",
  getProjectsListSuccess: "[projects] getProjectsListSuccess",

  getProjectStart: "[projects] getProjectStart",
  getProjectFailed: "[projects] getProjectFailed",
  getProjectSuccess: "[projects] getProjectSuccess",

  getProjectUnitsStart: "[projects] getProjectUnitsStart",
  getProjectUnitsFailed: "[projects] getProjectUnitsFailed",
  getProjectUnitsSuccess: "[projects] getProjectUnitsSuccess",

  changeSearchValue: "[projects] changeSearchValue",
  changePagination: "[projects] changePagination",
  changeStatusValue: "[projects] changeStatusValue",
};

const mutations = {
  [mutationTypes.changePagination](state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },
  [mutationTypes.changeStatusValue](state, payload) {
    state.statusValue = payload;
  },

  [mutationTypes.getProjectsListStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getProjectsListSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.data = payload;
  },
  [mutationTypes.getProjectsListFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getProjectStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getProjectSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.item = payload;
  },
  [mutationTypes.getProjectFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getProjectUnitsStart](state) {
    // state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getProjectUnitsSuccess](state, payload) {
    // state.isLoading = false;
    state.errors = [];
    state.itemUnits = payload;
  },
  [mutationTypes.getProjectUnitsFailed](state, payload) {
    // state.isLoading = false;
    state.errors = payload;
  },
};

export const actionTypes = {
  getProjectsList: "[projects] getProjectsList",
  getProject: "[projects] getProject",
  getProjectsUnits: "[projects] getProjectsUnits",
};

const actions = {
  async [actionTypes.getProjectsList](context, payload) {
    try {
      context.commit(mutationTypes.getProjectsListStart);

      const res = await api.getProjectsList(payload);
      context.commit(mutationTypes.getProjectsListSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getProjectsListFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getProject](context, payload) {
    try {
      context.commit(mutationTypes.getProjectStart);

      const res = await api.getProject(payload);
      context.commit(mutationTypes.getProjectSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getProjectFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getProjectsUnits](context, { id, data }) {
    try {
      context.commit(mutationTypes.getProjectUnitsStart);

      const res = await api.getProjectUnits(id, data);
      context.commit(mutationTypes.getProjectUnitsSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getProjectUnitsFailed, e.response.data.error);
      return false;
    }
  },
};

export const getterTypes = {
  totalItems: "[projects] totalItems",
  totalPages: "[projects] totalPages",
};
const getters = {
  [getterTypes.totalPages](state) {
    return state.data.pages || 0;
  },
  [getterTypes.totalItems](state) {
    return state.data.total || 0;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
